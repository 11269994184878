import {
  Button,
  Divider,
  Typography,
  Spin,
  Popconfirm,
  message,
  Card,
} from "antd";
// import { LoadingOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import moment from "moment";
import WeekPicker from "../components/WeekPicker";
// import CustomEmptySSE from "../components/CustomEmptySSE";
import Register from "../components/Register";
import Ims from "../components/Ims";

import { useContext } from "react";
import { AuthContext } from "./authprovider";
import CustomEmpty from "../components/CustomEmpty";

const url = `${process.env.REACT_APP_NODE_API_URL}/service-node-performance`;
const queryKey = "service-node-performance";
const queryFn = async (date) => {
  const params = new URLSearchParams({
    year: `${date.year()}`,
    month: `${date.month() + 1}`,
    day: `${date.get("date")}`,
  });
  return await axios.get(`${url}?${params}`);
};
function ServiceNodePerformance() {
  const [date, setDate] = useState(moment());

  const { data, isLoading } = useQuery([queryKey, date], () => queryFn(date), {
    select: (data) => {
      if (data) {
        return data.data;
      }
    },
    onError: (error) => {
      message.error(error?.response?.data || error?.message);
    },
  });
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const destroy = useMutation(
    (id) => {
      return axios.delete(`${url}/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey, date]);
      },
      onError: (error) => {
        message.error(error?.response?.data || error?.message);
      },
    }
  );

  return (
    <>
      <Typography.Title level={3} style={{ color: "#545454" }}>
        Service Node Performance
      </Typography.Title>
      <Divider />
      <WeekPicker date={date} setDate={setDate} />
      {data && (user.id === data.userId || user.role === "admin") ? (
        <Popconfirm
          onConfirm={() => {
            destroy.mutate(data["_id"]);
          }}
          title="Hapus data?"
        >
          <Button danger style={{ marginLeft: 5 }} disabled={destroy.isLoading}>
            {destroy.isLoading ? <Spin /> : "Delete"}
          </Button>
        </Popconfirm>
      ) : null}
      <Spin spinning={isLoading}>
        {data ? (
          <>
            <Card title="IMS GBL" style={{ margin: "35px 0" }}>
              <Ims date={date} name="gbl" queryKey="ims-gbl" />
            </Card>
            <Card title="IMS KBU" style={{ margin: "35px 0" }}>
              <Ims date={date} name="kbu" queryKey="ims-kbu" />
            </Card>
            <Card title="SS SMG" style={{ margin: "35px 0" }}>
              <Ims date={date} name="smg" queryKey="ss-smg" />
            </Card>
            <Card title="Register" style={{ margin: "35px 0" }}>
              <Register date={date} />
            </Card>
          </>
        ) : (
          <CustomEmpty {...{ queryKey, date, url }} />
        )}
      </Spin>
    </>
  );
}

export default ServiceNodePerformance;
