import { DatePicker } from "antd";
import "moment/locale/en-gb";
import locale from "antd/es/date-picker/locale/en_GB";

const WeekPicker = ({ date, setDate }) => {
  return (
    <DatePicker
      onChange={(date) => {
        setDate(date);
      }}
      allowClear={false}
      picker="week"
      defaultValue={date}
      locale={locale}
      format="YYYY-WW"
      style={{ marginBottom: "16px" }}
    />
  );
};

export default WeekPicker;
