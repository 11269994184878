function preprocess(links, xAxis) {
  let filtered = [];
  links.forEach((link) => {
    const { witel, ["_id"]: id, ...rest } = link;
    for (let [key, value] of Object.entries(rest)) {
      filtered.push({ witel, type: key, value: value });
    }
  });
  return filtered.sort((a, b) => a.value - b.value);
}

function color(text) {
  const number = parseFloat(text);
  if (number < 50) {
    return { backgroundColor: "#73E14C" };
  } else if (number >= 50 && number < 75) {
    return { backgroundColor: "#EDF03D" };
  } else {
    return { backgroundColor: "#E45B5B", color: "white" };
  }
}

export { preprocess, color };
