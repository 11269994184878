import { Table, Typography } from "antd";

const { Text } = Typography;

function color(text) {
  const number = parseFloat(text);
  if (number < 50) {
    return { backgroundColor: "#73E14C", padding: 2 };
  } else if (number >= 50 && number < 75) {
    return { backgroundColor: "#EDF03D", padding: 2 };
  } else {
    return { backgroundColor: "#E45B5B", padding: 2, color: "white" };
  }
}

const secondaryColumn = [
  {
    title: "Witel",
    dataIndex: "witel",
    align: "center",
    filters: [
      "Semarang",
      "Yogyakarta",
      "Solo",
      "Purwokerto",
      "Magelang",
      "Kudus",
      "Pekalongang",
    ].map((item) => ({
      text: item,
      value: item,
    })),
    onFilter: (value, record) => {
      return record.witel.indexOf(value.toLowerCase()) === 0;
    },
  },
  {
    title: "Metro A",
    dataIndex: "Metro A",
    align: "center",
    render(text, record) {
      return {
        props: {
          style: { ...color(record.occupy) },
        },
        children: <div>{text}</div>,
      };
    },
  },
  {
    title: "Metro B",
    dataIndex: "Metro B",
    align: "center",
    render(text, record) {
      return {
        props: {
          style: {
            ...color(record.occupy),
          },
        },
        children: <div>{text}</div>,
      };
    },
  },
  // {
  //   title: "Current Week Max Bandwidth Utilization(%)",
  //   dataIndex: "occupy",
  //   align: "center",
  //   render(text, record) {
  //     return {
  //       children: (
  //         <Text strong style={{ ...color(text) }}>
  //           {text}
  //         </Text>
  //       ),
  //     };
  //   },
  //   sorter: (a, b) => a.occupy - b.occupy,
  // },
];

const SecondaryTable = ({ data }) => {
  return (
    <Table
      columns={secondaryColumn}
      dataSource={data}
      pagination={{ defaultPageSize: 5 }}
      rowKey={(record) => record["_id"]}
      bordered
    />
  );
};

export default SecondaryTable;
