import { useContext } from "react";
import { AuthContext } from "./authprovider";
import { Navigate } from "react-router-dom";
export default function AdminOnly({ children }) {
  const { user } = useContext(AuthContext);
  console.log("data: ", user);
  if (user?.role === "user") {
    return <Navigate to="/metro-link" replace />;
  }
  return children;
}
