import { DualAxes } from "@ant-design/plots";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const customSpinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const CustomDualAxes = ({ data, title, seriesField, yField }) => {
  // const maxValue = data?.primary.reduce((prev, curr) => {
  //   return prev < curr.value ? curr.value : prev;
  // }, 0);
  // const maxAsr = data?.secondary.reduce((prev, curr) => {
  //   return prev < curr.asr ? curr.asr : prev;
  // }, 0);
  // console.log(`${title}\n value(max): ${maxValue}, asr(max): ${maxAsr}`);
  const config = {
    xField: "date",
    yField,
    yAxis: {
      ttl: {
        min: 0,
      },
      asr: {
        min: 0,
      },
      value: {
        // max: Math.ceil(maxValue / 50000) * 50000,
      },
    },
    legend: {
      position: "top-left",
      offsetY: -5,
    },
    geometryOptions: [
      {
        geometry: "column",
        isGroup: true,
        seriesField,
        dodgePadding: 0,
        columnWidthRatio: 0.7,
      },
      {
        geometry: "line",
        lineStyle: {
          lineWidth: 3,
          stroke: "orange",
          tickCount: 7,
        },
        smooth: true,
      },
    ],
  };

  return (
    <Spin indicator={customSpinIcon} spinning={data === null} delay={500}>
      <p className="title" style={{ textAlign: "center" }}>
        {title}
      </p>
      <DualAxes
        {...config}
        data={
          data
            ? [data.primary, data.secondary]
            : [[{ date: "", type: "", y1: 0 }], [{ date: "", y2: 0 }]]
        }
      />
    </Spin>
  );
};

export default CustomDualAxes;
// const sample1 = [
//   {
//     date: "23-May",
//     type: "MT_Attempt",
//     value: 430580,
//     asr: 63.11,
//   },
//   {
//     date: "23-May",
//     type: "MT_Answer",
//     value: 271730,
//     asr: 63.11,
//   },
//   {
//     date: "24-May",
//     type: "MT_Attempt",
//     value: 416681,
//     asr: 61.79,
//   },
//   {
//     date: "24-May",
//     type: "MT_Answer",
//     value: 257460,
//     asr: 61.79,
//   },
//   {
//     date: "25-May",
//     type: "MT_Attempt",
//     value: 407390,
//     asr: 62.19,
//   },
//   {
//     date: "25-May",
//     type: "MT_Answer",
//     value: 253345,
//     asr: 62.19,
//   },
//   {
//     date: "26-May",
//     type: "MT_Attempt",
//     value: 219290,
//     asr: 54.93,
//   },
//   {
//     date: "26-May",
//     type: "MT_Answer",
//     value: 120461,
//     asr: 54.93,
//   },
//   {
//     date: "27-May",
//     type: "MT_Attempt",
//     value: 433547,
//     asr: 61.23,
//   },
//   {
//     date: "27-May",
//     type: "MT_Answer",
//     value: 265467,
//     asr: 61.23,
//   },
//   {
//     date: "28-May",
//     type: "MT_Attempt",
//     value: 296984,
//     asr: 60.71,
//   },
//   {
//     date: "28-May",
//     type: "MT_Answer",
//     value: 180289,
//     asr: 60.71,
//   },
//   {
//     date: "29-May",
//     type: "MT_Attempt",
//     value: 159023,
//     asr: 56.13,
//   },
//   {
//     date: "29-May",
//     type: "MT_Answer",
//     value: 89262,
//     asr: 56.13,
//   },
// ];
// const sample2 = [
//   { date: "23-May", Asr: 63.11 },
//   { date: "24-May", Asr: 61.79 },
//   { date: "25-May", Asr: 62.19 },
//   { date: "26-May", Asr: 54.93 },
//   { date: "27-May", Asr: 61.23 },
//   { date: "28-May", Asr: 60.71 },
//   { date: "29-May", Asr: 56.13 },
// ];
