import {
  Button,
  Popconfirm,
  Table,
  Typography,
  Spin,
  Divider,
  message,
} from "antd";
import axios from "axios";
// import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { useContext } from "react";
import { useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import CustomEmpty from "../components/CustomEmpty";
import Headline from "../components/Headline";
import WeekPicker from "../components/WeekPicker";
import { AuthContext } from "./authprovider";

// const { Text } = Typography;
// const customSpinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function offset(data) {
  const flag = {};
  let sto = null;
  let idx = null;
  let curr = 0;
  for (let [index, val] of data.entries()) {
    if (val.sto !== sto || curr > 5) {
      if (curr > 5) {
        // console.log("curr lebih dari 5");
        curr = 0;
      }
      sto = val.sto;
      idx = `${index}`;
      flag[idx] = { rowSpan: 1 };
    } else {
      flag[idx].rowSpan += 1;
    }
    curr += 1;
  }
  // console.log("flag: ", flag);
  return flag;
}

const queryKey = "OkupansiCDNReport";

const url = `${process.env.REACT_APP_NODE_API_URL}/okupansi-cdn`;
async function queryFn(date) {
  const params = new URLSearchParams({
    year: `${date.year()}`,
    month: `${date.month() + 1}`,
    day: `${date.get("date")}`,
  });
  return axios.get(url + "?" + params);
}

function color(text) {
  const number = parseFloat(text);
  if (number < 50) {
    return { backgroundColor: "#73E14C", padding: 2 };
  } else if (number >= 50 && number < 75) {
    return { backgroundColor: "#EDF03D", padding: 2 };
  } else {
    return { backgroundColor: "#E45B5B", padding: 2, color: "white" };
  }
}

function OkupansiCDN() {
  const [date, setDate] = useState(moment());
  const [page, setPage] = useState(1);
  const { isLoading, data } = useQuery([queryKey, date], () => queryFn(date), {
    onSuccess: (data) => {
      if (data) {
        setRowSpan(offset(data.data));
      }
    },
    select: (data) => {
      if (data) {
        return data.data;
      }
    },
    onError: (error) => {
      message.error(error?.response?.data || error?.message);
    },
  });
  const [rowSpan, setRowSpan] = useState([]);
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  const destroy = useMutation(
    ({ id }) => {
      return axios.delete(`${url}/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey, date]);
      },
      onError: (error) => {
        message.error(error?.response?.data || error?.message);
      },
    }
  );

  const columns = [
    {
      title: "STO",
      align: "center",
      dataIndex: "sto",
      onCell: (row, index) => {
        // console.log(
        //   "at: ",
        //   `${index + (page - 1) * 6}`,
        //   "rowSpan: ",
        //   rowSpan[`${index + (page - 1) * 6}`]
        // );
        return rowSpan[`${index + (page - 1) * 6}`] ?? { rowSpan: 0 };
      },
    },
    { title: "CDN", dataIndex: "cdn", align: "center" },
    { title: "Hostname", dataIndex: "hostname", align: "center" },
    { title: "Cap CDN (G)", dataIndex: "cap cdn", align: "center" },
    { title: "Cap Link", dataIndex: "cap link", align: "center" },
    { title: "OCC (G)", dataIndex: "occ g", align: "center" },
    {
      title: "OCC %",
      dataIndex: "occ_pcg",
      align: "center",
      render(text) {
        return {
          props: {
            style: { ...color(text) },
          },
          children: <div>{text}</div>,
        };
      },
    },
  ];

  return (
    <>
      <Typography.Title level={3} style={{ color: "#545454" }}>
        Okupansi CDN
      </Typography.Title>
      <Divider />
      <WeekPicker date={date} setDate={setDate} />
      {data?.["_id"] && (user.id === data.userId || user.role === "admin") ? (
        <Popconfirm
          title="Hapus data?"
          onConfirm={() => destroy.mutate({ id: data["_id"] })}
        >
          <Button danger style={{ marginLeft: 5 }} disabled={destroy.isLoading}>
            {destroy.isLoading ? <Spin /> : "Delete"}
          </Button>
        </Popconfirm>
      ) : null}
      <Spin spinning={isLoading}>
        {data?.data ? (
          <>
            <Headline totalinks={data.totalinks} totalsaved={data.totalsaved} />
            <Table
              columns={columns}
              dataSource={data.data}
              pagination={{ defaultPageSize: 6 }}
              onChange={({ current }) => {
                // console.log("current: ", current);
                setPage(current);
              }}
              rowKey={(record) => record["_id"]}
              bordered
            />
          </>
        ) : (
          <CustomEmpty queryKey={queryKey} date={date} url={url} />
        )}
      </Spin>
    </>
  );
}

export default OkupansiCDN;
