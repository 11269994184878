import {
  Button,
  Divider,
  message,
  Popconfirm,
  Spin,
  Table,
  Typography,
} from "antd";
// import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import WeekPicker from "../components/WeekPicker";
import CustomEmpty from "../components/CustomEmpty";
import { color } from "../utils/utils";
import Headline from "../components/Headline";
import { useContext } from "react";
import { AuthContext } from "./authprovider";
import axios from "axios";

const url = process.env.REACT_APP_NODE_API_URL;
const queryKey = "BrasPehsi";
// const customSpinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const queryFn = async (date) => {
  const params = new URLSearchParams({
    year: `${date.year()}`,
    month: `${date.month() + 1}`,
    day: `${date.get("date")}`,
  });
  return axios.get(`${url}/bras-pehsi?${params}`);
};

const columns = [
  {
    title: "Witel",
    align: "center",
    dataIndex: "witel",
  },
  { title: "Node A", dataIndex: "node a", align: "center" },
  { title: "Node B", dataIndex: "node b", align: "center" },
  { title: "Cap (G)", dataIndex: "cap g", align: "center" },
  { title: "OCC (G)", dataIndex: "occ g", align: "center" },
  {
    title: "OCC %",
    dataIndex: "occ_pcg",
    align: "center",
    render(text, record) {
      return {
        props: {
          style: { ...color(text) },
        },
        children: <div>{text}</div>,
      };
    },
  },
];

function BrasPehsi() {
  const [date, setDate] = useState(moment());
  const { isLoading, data } = useQuery([queryKey, date], () => queryFn(date), {
    select: (data) => {
      if (data) {
        return data?.data;
      }
    },
    onSuccess: (data) => {
      // console.log("data: ", data);
    },
    onError: (error) => {
      message.error(error?.response?.data || error?.message);
    },
  });
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  const destroy = useMutation(
    (id) => {
      return fetch(`${url}/bras-pehsi/${id}`, {
        method: "DELETE",
        credentials: "include",
        mode: "cors",
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey, date]);
      },
      onError: (error) => {
        message.error(error?.response?.data || error?.message);
      },
    }
  );

  return (
    <>
      <Typography.Title level={3} style={{ color: "#545454" }}>
        Bras PE - HSI
      </Typography.Title>
      <Divider />
      <WeekPicker date={date} setDate={setDate} />
      {data && (user.id === data?.userId || user.role === "admin") ? (
        <Popconfirm
          onConfirm={() => destroy.mutate(data["_id"])}
          title="Are you sure?"
        >
          <Button danger style={{ marginLeft: 5 }} disabled={destroy.isLoading}>
            {destroy.isLoading ? <Spin /> : "Delete"}
          </Button>
        </Popconfirm>
      ) : null}
      <Spin spinning={isLoading}>
        {data ? (
          <>
            <Headline totalinks={data.totalinks} totalsaved={data.totalsaved} />
            <Table
              dataSource={data.data}
              columns={columns}
              rowKey={(record) => record["_id"]}
              bordered
            />
          </>
        ) : (
          <CustomEmpty
            queryKey={queryKey}
            date={date}
            url={`${url}/bras-pehsi`}
          />
        )}
      </Spin>
    </>
  );
}

export default BrasPehsi;
