import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Image,
  Modal,
  Row,
  Select,
  Typography,
  Spin,
} from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "../styles/signin.module.css";
import { useRef } from "react";
import { useMutation } from "react-query";
import axios from "axios";

const { Option } = Select;
const customSpinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const unit = [
  "IPSN",
  "Backbone & DEFA Operation",
  "Wireless Operation",
  "IS Operation Support",
  "Infra Maintenance",
];

const url = process.env.REACT_APP_NODE_API_URL;

function SignUp() {
  const [form] = Form.useForm();
  const recaptchaRef = useRef();
  const user = useMutation(
    ({ data }) => {
      return axios.post(
        `${url}/user`,
        { ...data, token: recaptchaRef.current.getValue() },
        {
          withCredentials: true,
        }
      );
    },
    {
      onSuccess: () => {
        recaptchaRef.current.reset();
        Modal.success({
          title: (
            <Typography.Title level={3}>
              Akun berhasil terdaftar
            </Typography.Title>
          ),
          content: (
            <div>
              <Typography.Text>
                Akun anda belum aktif, Hubungi admin untuk mengaftikan akun anda
              </Typography.Text>
            </div>
          ),
          onOk() {},
        });
      },
      onError: (error) => {},
      onSettled: () => {
        form.resetFields(["nama", "nik", "unit", "password"]);
        recaptchaRef.current.reset();
      },
    }
  );
  return (
    <Row align="middle" justify="center" className={styles.row}>
      <Col className={styles.col}>
        <Spin spinning={user.isLoading} indicator={customSpinIcon}>
          <div className={styles.header}>
            <Image
              src="/telkom.png"
              preview={false}
              width={55}
              style={{ marginBottom: 5 }}
            />
            <Typography.Title level={3} className={styles.title}>
              Sign Up
            </Typography.Title>
          </div>
          <Form
            form={form}
            layout="vertical"
            style={{ paddingLeft: 5, paddingRight: 5 }}
            onFinish={(data) => user.mutate({ data })}
          >
            <Row justify="center">
              <Typography.Text type="danger" strong>
                {user.isError
                  ? user.error?.response?.data ?? user.error?.message
                  : null}
              </Typography.Text>
            </Row>
            <Form.Item
              label={
                <Typography.Text strong className={styles.label}>
                  Nama
                </Typography.Text>
              }
              name="nama"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: "Nama harus diisi",
                },
              ]}
            >
              <Input placeholder="Masukan nama anda" size="large" id="nama" />
            </Form.Item>
            <Row gutter={6}>
              <Col>
                <Form.Item
                  label={
                    <Typography.Text strong className={styles.label}>
                      NIK
                    </Typography.Text>
                  }
                  name="nik"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: "NIK tidak boleh kosong",
                    },
                    {
                      len: 6,
                      type: "string",
                      message: "Tidak sesuai format",
                    },
                  ]}
                >
                  <Input
                    placeholder="Masukan NIK"
                    size="large"
                    type="number"
                    style={{ width: 140 }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={
                    <Typography.Text strong className={styles.label}>
                      Unit
                    </Typography.Text>
                  }
                  name="unit"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: "Unit tidak boleh kosong",
                    },
                  ]}
                  style={{ marginLeft: 0, marginRight: 0, padding: 0 }}
                >
                  <Select
                    placeholder="Pilih Unit"
                    size="large"
                    style={{ width: 160, margin: 0, padding: 0 }}
                  >
                    {unit.map((value) => (
                      <Option value={value}>{value}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={
                <Typography.Text strong className={styles.label}>
                  Password
                </Typography.Text>
              }
              htmlFor="passowrd"
              name="password"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: "Password tidak boleh kosong",
                },
                {
                  min: 8,
                  message: "Password kependekan (min: 8)",
                },
              ]}
            >
              <Input.Password
                placeholder="Masukan Password"
                size="large"
                id="password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Row justify="center">
              <Form.Item>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_NODE_CAPTCHA_SITE_KEY}
                />
              </Form.Item>
            </Row>
            <Form.Item>
              <Button htmlType="submit" size="large" className={styles.btn}>
                {user.isLoading ? <LoadingOutlined spin /> : "Sign In"}
              </Button>
            </Form.Item>
          </Form>
          <Divider />
          <Row justify="center" style={{ marginBottom: 15 }}>
            <Col
              span={24}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography.Text>sudah terdaftar?</Typography.Text>
            </Col>
            <Col>
              <Typography.Text strong underline>
                <Link to="/signin">Masuk</Link>
              </Typography.Text>
            </Col>
          </Row>
        </Spin>
      </Col>
    </Row>
  );
}

export default SignUp;
