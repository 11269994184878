import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "./authprovider";

function AuthRequire({ children }) {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  if (!user) {
    return <Navigate to="/signin" replace state={{ from: location }} />;
  }
  return children;
}

export default AuthRequire;
