import { Card, Col, Row, Statistic } from "antd";

const SPAN = 5;

function Headline({ totalinks, totalsaved, treshold = 75 }) {
  return (
    <Row style={{ margin: "10px 0 30px 0" }} justify="space-between">
      <Col span={SPAN}>
        <Card>
          <Statistic title="Total Link" value={totalinks ?? 0} />
        </Card>
      </Col>
      <Col span={SPAN}>
        <Card>
          <Statistic
            title={`Kurang dari ${treshold}%`}
            value={totalsaved ?? 0}
            decimalSeparator={2}
          />
        </Card>
      </Col>
      <Col span={SPAN}>
        <Card>
          <Statistic
            title="Pass Percentage"
            value={
              totalsaved ? `${Math.round((totalsaved / totalinks) * 100)}%` : 0
            }
          />
        </Card>
      </Col>
    </Row>
  );
}

export default Headline;
