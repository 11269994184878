import {
  Col,
  Divider,
  Form,
  Input,
  Image,
  Row,
  Button,
  Typography,
  Spin,
} from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "../styles/signin.module.css";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "./authprovider";
import { useMutation } from "react-query";
import axios from "axios";
import { useRef } from "react";

axios.defaults.withCredentials = true;
const url = process.env.REACT_APP_NODE_API_URL;
const customSpinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function SignIn() {
  const [token, setToken] = useState(null);
  const [form] = Form.useForm();
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const recaptchaRef = useRef();

  const session = useMutation(
    async ({ data, token }) => {
      const formData = new FormData();
      formData.append("nik", data.nik);
      formData.append("password", data.password);
      formData.append("token", token);
      return axios.post(`${url}/session`, formData, {
        withCredential: true,
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    {
      onSuccess: ({ data }) => {
        setUser(data);
        const origin = location.state?.from?.pathname || "/wecare";
        navigate(origin);
      },
      onError: (error) => {
        form.resetFields(["nik", "password"]);
      },
      onSettled: () => {
        recaptchaRef.current.reset();
        setToken(null);
      },
    }
  );

  if (user) {
    const origin = location.state?.from?.pathname || "/wecare";
    return <Navigate to={origin} />;
  }

  return (
    <Row align="middle" justify="center" className={styles.row}>
      <Col className={styles.col}>
        <Spin spinning={session.isLoading} indicator={customSpinIcon}>
          <div className={styles.header}>
            <Image
              src="/telkom.png"
              preview={false}
              width={55}
              style={{ marginBottom: 5 }}
            />
            <Typography.Title level={3} className={styles.title}>
              Sign In
            </Typography.Title>
          </div>
          <Form
            form={form}
            onFinish={(data) => session.mutate({ data, token })}
            layout="vertical"
            requiredMark={false}
          >
            <Row justify="center">
              <Typography.Text type="danger" strong>
                {session.isError
                  ? session.error?.response?.data ?? session.error?.message
                  : null}
              </Typography.Text>
            </Row>
            <Form.Item
              key="nik"
              label={
                <Typography.Text strong className={styles.label}>
                  NIK
                </Typography.Text>
              }
              name="nik"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: "NIK tidak boleh kosong",
                },
              ]}
            >
              <Input placeholder="Masukan NIK" size="large" id="nik" />
            </Form.Item>
            <Form.Item
              key="password"
              label={
                <Typography.Text strong className={styles.label}>
                  Password
                </Typography.Text>
              }
              htmlFor="passowrd"
              name="password"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: "Password tidak boleh kosong",
                },
              ]}
            >
              <Input.Password
                placeholder="Masukan Password"
                size="large"
                id="password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item key="captcha">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_NODE_CAPTCHA_SITE_KEY}
                size="normal"
                onChange={(value) => {
                  setToken(value);
                }}
              />
            </Form.Item>
            <Form.Item key="submit">
              <Button
                htmlType="submit"
                size="large"
                className={styles.btn}
                disabled={token === null}
              >
                {session.isLoading ? <LoadingOutlined spin /> : "Sign In"}
              </Button>
            </Form.Item>
          </Form>
          <Divider />
          <Row justify="center" style={{ marginBottom: 15 }}>
            <Col
              span={24}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography.Text>belum terdaftar?</Typography.Text>
            </Col>
            <Col>
              <Typography.Text strong underline>
                <Link to="/signup">Daftar</Link>
              </Typography.Text>
            </Col>
          </Row>
        </Spin>
      </Col>
    </Row>
  );
}

export default SignIn;
