import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import moment from "moment";
import CustomEmpty from "../components/CustomEmpty";
import StackedColumn from "../components/StackedColumn";
import WeekPicker from "../components/WeekPicker";
import {
  Button,
  Card,
  Col,
  Divider,
  message,
  Popconfirm,
  Row,
  Spin,
  Typography,
} from "antd";
// import { LoadingOutlined } from "@ant-design/icons";
import Headline from "../components/Headline";
import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "./authprovider";

const url = `${process.env.REACT_APP_NODE_API_URL}/metro-bras`;
const queryKey = "MetroBrasReport";
// const customSpinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const queryFn = async (date) => {
  const params = new URLSearchParams({
    year: date.year(),
    month: date.month() + 1,
    day: date.get("date"),
  });

  return axios.get(`${url}?` + params, {
    withCredentials: true,
  });
};

function preprocess(links) {
  let filtered = [];
  links.forEach((link) => {
    const { metro, _id: id, ...rest } = link;
    for (let [key, value] of Object.entries(rest)) {
      filtered.push({ metro, type: key, value: value });
    }
  });
  return filtered.sort((a, b) => a.value - b.value);
}

function MetroBras() {
  const [date, setDate] = useState(moment());
  const { isLoading, data } = useQuery([queryKey, date], () => queryFn(date), {
    select: (data) => {
      if (data) {
        return data.data;
      }
    },
    onError: (error) => {
      message.error(error?.response?.data || error?.message);
    },
  });
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const destroy = useMutation(
    ({ id }) => {
      return axios.delete(`${url}/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey, date]);
      },
      onError: (error) => {
        message.error(error?.response?.data || error?.message);
      },
    }
  );

  return (
    <>
      <Typography.Title level={3} style={{ color: "#545454" }}>
        Metro Bras
      </Typography.Title>
      <Divider />
      <WeekPicker date={date} setDate={setDate} />
      {data?.week && (user.id === data.userId || user.role === "admin") ? (
        <Popconfirm
          title="Hapus data?"
          onConfirm={() => destroy.mutate({ id: data["_id"] })}
        >
          <Button danger style={{ marginLeft: 5 }} disabled={destroy.isLoading}>
            {destroy.isLoading ? <Spin /> : "Delete"}
          </Button>
        </Popconfirm>
      ) : null}
      <Spin spinning={isLoading}>
        {data?.year ? (
          <>
            <Headline totalinks={data.totalinks} totalsaved={data.totalsaved} />
            <Row justify="start">
              <Col span={14}>
                <Card title="Metro to Bras">
                  <StackedColumn
                    data={preprocess(data.nodes)}
                    xAxis="metro"
                    yAxis="value"
                  />
                </Card>
              </Col>
            </Row>
          </>
        ) : (
          <CustomEmpty queryKey={queryKey} date={date} url={url} />
        )}
      </Spin>
    </>
  );
}

export default MetroBras;
