import { Divider, Typography } from "antd";
import { InlineEditTable } from "../components/InlineEditTable";

function ProgramKerja() {
  return (
    <>
      <Typography.Title level={3} style={{ color: "#545454" }}>
        Program Kerja
      </Typography.Title>
      <Divider />
      <InlineEditTable />
    </>
  );
}

export default ProgramKerja;
