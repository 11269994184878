import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./routes/dashboard";
import SignIn from "./routes/signin";
import SignUp from "./routes/signup";
import Users from "./routes/users";
import AuthRequire from "./routes/authrequire";
import MetroLink from "./routes/metro_link";
import MetroBras from "./routes/metro_bras";
import OkupansiCDN from "./routes/okupansi_cdn";
import OkupansiIPBackbone from "./routes/okupansi_ip_backbone";
import OkupansiNAT from "./routes/okupansi_nat";
import OkupansiPcef from "./routes/okupansi_pcef";
import BrasPehsi from "./routes/bras_pehsi";
import PehsiTera from "./routes/pehsi_tera";
import WeCare from "./routes/wecare";
import Home from "./routes/home";
import ProgramKerja from "./routes/programkerja";
import "antd/dist/antd.css";
import { AuthProvider } from "./routes/authprovider";
import { QueryClientProvider, QueryClient } from "react-query";
import ServiceNodePerformance from "./routes/service_node_performance";
import AdminOnly from "./routes/adminonly";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route
            path="/"
            element={
              <AuthRequire>
                <Dashboard />
              </AuthRequire>
            }
          >
            <Route path="ip-network-utilisasi" element={<Home />} />
            <Route path="program-kerja" element={<ProgramKerja />} />
            <Route path="wecare" element={<WeCare />} />
            <Route path="metro-link" element={<MetroLink />} />
            <Route path="metro-bras" element={<MetroBras />} />
            <Route path="okupansi-cdn" element={<OkupansiCDN />} />
            <Route
              path="okupansi-ip-backbone"
              element={<OkupansiIPBackbone />}
            />
            <Route path="okupansi-nat" element={<OkupansiNAT />} />
            <Route path="okupansi-pcef" element={<OkupansiPcef />} />
            <Route path="bras-pehsi" element={<BrasPehsi />} />
            <Route path="pehsi-tera" element={<PehsiTera />} />
            <Route
              path="service-node-performance"
              element={<ServiceNodePerformance />}
            />
            <Route
              path="users"
              element={
                <AdminOnly>
                  <Users />
                </AdminOnly>
              }
            />
          </Route>
          <Route path="*" element={<h1>Page not found!</h1>} />
        </Routes>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
