// import { ReactComponent as Test } from "../test.svg";
// import { ReactComponent as Complex } from "../block.drawio.svg";
// import { ReactComponent as Block } from "../drawio.svg";
// import Coba from "../components/Coba";
import Coba2 from "../components/Coba2";
import "../styles/global.module.css";
import { Col, Divider, Row, Typography } from "antd";
import WeekPicker from "../components/WeekPicker";
import { useState } from "react";
import moment from "moment";
export default function Home() {
  const [date, setDate] = useState(moment());
  return (
    <>
      <Typography.Title level={3} style={{ color: "#545454" }}>
        IP Network Utilisasi Link
      </Typography.Title>
      <Divider />
      <Row>
        <Col span={24}>
          <WeekPicker date={date} setDate={setDate} />
        </Col>
      </Row>

      <Row
        justify="center"
        style={{
          paddingTop: 8,
          // width: "80%",
        }}
      >
        <Col span={20}>
          <Coba2 date={date} setDate={setDate} />
        </Col>
      </Row>
    </>
  );
}
