import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Col,
  Spin,
  Row,
  Typography,
  Divider,
  Button,
  Popconfirm,
  Card,
  message,
} from "antd";
// import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import StackedColumn from "../components/StackedColumn";

// import PrimaryTable from "../components/PrimaryTable";
import SecondaryTable from "../components/SecondaryTable";
import WeekPicker from "../components/WeekPicker";
import CustomEmpty from "../components/CustomEmpty";
import Headline from "../components/Headline";
import { useContext } from "react";
import { AuthContext } from "./authprovider";
import axios from "axios";

const url = `${process.env.REACT_APP_NODE_API_URL}/metro-link`;
const queryKey = "MetroLinkReport";
// const customSpinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      localStorage.setItem("user", null);
      window.location = "/signin";
    }
    return Promise.reject(error);
  }
);

function preprocess(links) {
  let filtered = [];
  links.forEach((link) => {
    const { witel, _id: id, ...rest } = link;
    for (let [key, value] of Object.entries(rest)) {
      filtered.push({ witel, type: key, value: value });
    }
  });
  return filtered.sort((a, b) => a.value - b.value);
}

const queryFn = async (date) => {
  const params = new URLSearchParams({
    year: date.year(),
    month: date.month() + 1,
    day: date.get("date"),
  });
  return axios.get(`${url}?${params}`, {
    withCredentials: "include",
  });
};

function MetroLink() {
  const [date, setDate] = useState(moment());
  const { isLoading, data } = useQuery(
    [queryKey, date],
    () => {
      return queryFn(date);
    },
    {
      select: (data) => {
        if (data) {
          return data.data;
        }
      },
      onError: (error) => {
        message.error(error?.response?.data || error?.message);
      },
    }
  );
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const destroy = useMutation(
    ({ id }) => {
      return axios.delete(`${url}/${id}`, {
        withCredentials: "include",
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey, date]);
      },
      onError: (error) => {
        message.error(error?.response?.data || error?.message);
      },
    }
  );

  return (
    <>
      <Typography.Title level={3} style={{ color: "#545454" }}>
        Metro E-Link
      </Typography.Title>
      <Divider />
      <WeekPicker date={date} setDate={setDate} />
      {data?.primary && (user.id === data.userId || user.role === "admin") ? (
        <Popconfirm
          onConfirm={() => destroy.mutate({ id: data["_id"] })}
          title="Are you sure?"
        >
          <Button danger style={{ marginLeft: 5 }}>
            {destroy.isLoading ? <Spin /> : "Delete"}
          </Button>
        </Popconfirm>
      ) : null}
      <Spin spinning={isLoading}>
        {data?.primary ? (
          <>
            <Headline
              totalinks={data.totalinks}
              totalsaved={data.totalsaved}
              treshold={50}
            />
            <Row justify="space-between">
              <Col span={14}>
                <Card title="Metro">
                  <StackedColumn
                    data={preprocess(data.primary)}
                    xAxis="witel"
                    yAxis="value"
                  />
                </Card>
              </Col>
              <Col span={9}>
                <Card title="Over 50%">
                  <SecondaryTable data={data ? data.secondary : null} />
                </Card>
              </Col>
              {/* <Col span={12}>
              <PrimaryTable report={data} />
              <SecondaryTable data={data ? data.secondary : null} />
            </Col> */}
            </Row>
          </>
        ) : (
          <CustomEmpty queryKey={queryKey} date={date} url={url} />
        )}
      </Spin>
    </>
  );
}

export default MetroLink;
