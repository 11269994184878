import { Col, Row } from "antd";
import { useQuery } from "react-query";
import moment from "moment";
import CustomDualAxes from "./CustomDualAxes";
import axios from "axios";
moment.locale("en-gb");

const url = `${process.env.REACT_APP_NODE_API_URL}/register`;
const queryKey = `register`;
const queryFn = async (date) => {
  // console.log("date: ", date);
  const params = new URLSearchParams({
    year: `${date.year()}`,
    month: `${date.month() + 1}`,
    day: `${date.get("date")}`,
  });
  return axios.get(url + "?" + params, { withCredentials: true });
};

function split(data) {
  const { Tanggal, "TTL REGISTER": ttl, ...rest } = data;
  const date = moment(Tanggal).format("DD-MMMM");
  return Object.entries(rest).map(([ip, value]) => {
    return { ip, value, date: date, ttl };
  });
}

function prepare(data) {
  let primary = [];
  let secondary = [];
  data.forEach((element) => {
    const { Tanggal, "TTL REGISTER": ttl } = element;
    const date = moment(Tanggal).format("DD-MMMM");
    primary = [...primary, ...split(element)];
    secondary = [...secondary, { date, ttl }];
  });
  return { primary, secondary };
}

const yField = ["value", "ttl"];

export default function Register({ date }) {
  const { isError, data } = useQuery([queryKey, date], () => queryFn(date), {
    onSuccess: (data) => {},
    select: ({ data }) => {
      if (data) {
        const { kbu, gbl } = data;
        return { kbu: prepare(kbu), gbl: prepare(gbl) };
      }
    },
    staleTime: 300000,
  });

  if (isError) {
    return <h1>Error</h1>;
  }
  return (
    <>
      <Row gutter={[0, 0]} justify="space-around">
        <Col span={10} className="gutter-row">
          <CustomDualAxes
            data={data?.gbl ?? null}
            title="Register GBL"
            yField={yField}
            seriesField="ip"
          />
        </Col>
        <Col span={10} className="gutter-row">
          <CustomDualAxes
            data={data?.kbu ?? null}
            title="Register KBU"
            yField={yField}
            seriesField="ip"
          />
        </Col>
      </Row>
    </>
  );
}
