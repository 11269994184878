import {
  message,
  Button,
  Divider,
  Popconfirm,
  Table,
  Typography,
  Spin,
} from "antd";

import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import CustomEmpty from "../components/CustomEmpty";
import WeekPicker from "../components/WeekPicker";
import { useContext } from "react";
import { AuthContext } from "./authprovider";

function mergeCol(_, index) {
  if (index === 0 || index === 3) {
    return { colSpan: 0 };
  }
  return { colSpan: 1 };
}

function round(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

const columns = [
  {
    title: "Performance Indicator",
    dataIndex: "performance indicator",
    key: "performance indicator",
    align: "center",
    onCell: (index) => {
      if (index === 0 || index === 3) {
        return { colSpan: 18 };
      }
      return { colSpan: 1 };
    },
  },
  {
    title: "Unit",
    dataIndex: "unit",
    key: "unit",
    width: 50,
    align: "center",
    onCell: mergeCol,
  },
  {
    title: "Ranking TR4",
    dataIndex: "ranking tr4",
    key: "ranking tr4",
    align: "center",
    width: 100,
    onCell: mergeCol,
  },
  {
    title: "TGT",
    dataIndex: "tgt",
    key: "tgt",
    align: "center",
    width: 100,
    onCell: mergeCol,
  },
  ...[1, 2, 3, 4, 5, 6, 7].map((val) => ({
    title: `TR${val}`,
    children: [
      {
        title: "REAL",
        dataIndex: `tr${val} real`,
        key: `tr${val} real`,
        align: "center",
        onCell: mergeCol,
        render: (text) => {
          return round(parseFloat(text), 0);
        },
      },
      {
        title: "ACH",
        dataIndex: `tr${val} ach`,
        key: `tr${val} ach`,
        align: "center",
        onCell: mergeCol,
        render: (text) => {
          text = round(parseFloat(text) * 100, 0);
          return (
            <Typography.Text
              strong
              type={text < 100 ? "danger" : "success"}
            >{`${text}%`}</Typography.Text>
          );
        },
      },
    ],
  })),
];
const url = process.env.REACT_APP_NODE_API_URL;

async function queryFn(date) {
  const params = new URLSearchParams({
    year: `${date.year()}`,
    month: `${date.month() + 1}`,
    day: `${date.get("date")}`,
  });
  return await axios.get(`${url}/wecare?${params}`, {
    withCredentials: true,
  });
}

export default function WeCare() {
  const [date, setDate] = useState(moment());
  const wecareQuery = useQuery(["wecare", date], () => queryFn(date), {
    onError: (error) => {
      message.error(error?.response?.data || error?.message);
    },
    select: (data) => data.data,
  });
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const destroy = useMutation(
    (id) => {
      return axios.delete(`${url}/wecare/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["wecare", date]);
      },
      onError: (error) => {
        message.error(error?.response?.data || error?.message);
      },
    }
  );

  return (
    <>
      <Typography.Title level={3} style={{ color: "#545454" }}>
        WeCare Performance
      </Typography.Title>
      <Divider />
      <WeekPicker date={date} setDate={setDate} />
      {wecareQuery.data?.data ? (
        <Popconfirm
          title="Hapus data?"
          onConfirm={() => {
            destroy.mutate(wecareQuery.data["_id"]);
          }}
        >
          {user?.id === wecareQuery.data.id || user?.role === "admin" ? (
            <Button
              danger
              style={{ marginLeft: 5 }}
              disabled={destroy.isLoading}
            >
              {destroy.isLoading ? <Spin /> : "Delete"}
            </Button>
          ) : null}
        </Popconfirm>
      ) : null}
      <Spin spinning={wecareQuery.isLoading}>
        {wecareQuery.data?.data ? (
          <>
            <Table
              columns={columns}
              dataSource={wecareQuery.data.data ?? []}
              bordered
              rowKey={(record) => record["performance-indicator"]}
              size="small"
              pagination={false}
            />
          </>
        ) : (
          <CustomEmpty queryKey="wecare" date={date} url={`${url}/wecare`} />
        )}
      </Spin>
    </>
  );
}
