import { InboxOutlined } from "@ant-design/icons";
import { Upload, message, Spin } from "antd";
import axios from "axios";
import { useQueryClient, useMutation } from "react-query";
const { Dragger } = Upload;

const CustomEmpty = ({ queryKey, date, url }) => {
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(
    ({ file, date }) => {
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("year", date.year());
      formData.append("month", date.month() + 1);
      formData.append("day", date.get("date"));
      return axios.post(url, formData, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey, date]);
      },
      onError: (error) => {
        console.log(error);
        message.error(error.response?.data || error.message, 3);
      },
    }
  );

  return (
    <>
      <Dragger
        maxCount={1}
        accept=".xlsx"
        showUploadList={false}
        customRequest={(file) => {
          mutate({ file, date });
        }}
      >
        {isLoading ? (
          <Spin />
        ) : (
          <>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload excel file
            </p>
            <p className="ant-upload-hint">Pastikan file excel sudah benar</p>
          </>
        )}
      </Dragger>
    </>
  );
};

export default CustomEmpty;
