import { Col, Row } from "antd";
import { useQuery } from "react-query";
import moment from "moment";
import CustomDualAxes from "./CustomDualAxes";
import axios from "axios";
const url = `${process.env.REACT_APP_NODE_API_URL}/ims`;

const queryFn = async (date, name) => {
  const params = new URLSearchParams({
    year: `${date.year()}`,
    month: `${date.month() + 1}`,
    day: `${date.get("date")}`,
    name: name,
  });
  return axios.get(url + "?" + params, { withCredentials: true });
};

function split(data) {
  const { Tanggal, Asr: asr, ...rest } = data;
  const date = moment(Tanggal).format("DD-MMMM");
  return Object.entries(rest).map(([type, value]) => {
    return { type, value, date: date };
  });
}

function prepare(data) {
  let primary = [];
  let secondary = [];
  data.forEach((element) => {
    const { Tanggal, Asr: asr } = element;
    const date = moment(Tanggal).format("DD-MMMM");
    primary = [...primary, ...split(element)];
    secondary = [
      ...secondary,
      { date, asr: (Number.isFinite(asr) ? asr : 0) * 100 },
    ];
  });
  return { primary, secondary };
}

const yField = ["value", "asr"];

const getTitle = (name) => {
  switch (name) {
    case "gbl":
      return ["ASC IC IMS SM2M", "ASC OG IMS SM2M"];
    case "kbu":
      return ["ASC IC IMS YG2M", "ASC OG IMS YG2M"];
    case "smg":
      return ["ASC IC SM1S", "ASC OG SM1S"];
    default:
      return [];
  }
};

export default function Ims({ date, name, queryKey }) {
  const { data } = useQuery([queryKey, date], () => queryFn(date, name), {
    onSuccess: (data) => {},
    select: ({ data }) => {
      if (data) {
        const { ic, og, name } = data;
        return {
          ic: prepare(ic),
          og: prepare(og),
          names: getTitle(name),
        };
      }
    },
    staleTime: 300000,
  });
  return (
    <>
      <Row gutter={[0, 0]} justify="space-around">
        <Col span={10} className="gutter-row">
          <CustomDualAxes
            data={data?.ic ?? null}
            title={data?.names[0]}
            yField={yField}
            seriesField="type"
          />
        </Col>
        <Col span={10} className="gutter-row">
          <CustomDualAxes
            data={data?.og ?? null}
            title={data?.names[1]}
            yField={yField}
            seriesField="type"
          />
        </Col>
      </Row>
    </>
  );
}
