import React from "react";
import {
  Avatar,
  Col,
  Layout,
  Menu,
  Dropdown,
  Row,
  Space,
  Typography,
} from "antd";
import "antd/dist/antd.css";
import "../styles/global.module.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { AuthContext } from "./authprovider";
import { useMutation } from "react-query";
import axios from "axios";

const { Header, Content, Sider } = Layout;

const layoutStyle = {
  minHeight: "100vh",
};

const headerStyle = {
  backgroundColor: "#F8F8F8",
  border: "none",
  boxShadow: "none",
  margin: 0,
};
const contentStyle = {
  margin: "0 16px",
  padding: "0 32px 0 32px",
};

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem("WeCare", "wecare", <></>),
  getItem("IP Network Utilisasi", "ip-network-utilisasi", <></>),
  getItem("Program Kerja", "program-kerja", <></>),
  getItem("IPSN", "ipsn", <></>, [
    getItem("Metro E-Link", "metro-link", <></>),
    getItem("Metro Bras", "metro-bras", <></>),
    getItem("Okupansi CDN", "okupansi-cdn", <></>),
    getItem("Okupansi IP Backbone", "okupansi-ip-backbone", <></>),
    getItem("Okupansi NAT", "okupansi-nat", <></>),
    getItem("Okupansi PCEF", "okupansi-pcef", <></>),
    getItem("Bras PE - HSI", "bras-pehsi", <></>),
    getItem("PE - HSI TERA", "pehsi-tera", <></>),
    getItem("Service Node Performance", "service-node-performance", <></>),
  ]),
];

const url = process.env.REACT_APP_NODE_API_URL;

function Dashboard(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setUser } = useContext(AuthContext);
  const { mutate } = useMutation(
    () => {
      return axios.delete(`${url}/session`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        setUser(null);
      },
    }
  );

  function onClick({ key }) {
    if (key === "1") {
      navigate("/users");
    } else if (key === "2") {
      mutate();
    }
  }
  const show =
    user.role === "admin"
      ? [
          {
            label: "Users",
            key: "1",
          },
        ]
      : [];
  const menu = (
    <Menu
      onClick={onClick}
      style={{ width: 120 }}
      items={[
        {
          label: <Typography.Title level={5}> {user.nama}</Typography.Title>,
          disabled: true,
        },
        {
          type: "divider",
        },
        ...show,
        {
          label: "Sign Out",
          key: "2",
        },
      ]}
    />
  );

  return (
    <Layout style={layoutStyle}>
      <Sider
        collapsed={false}
        theme="light"
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          backgroundColor: "#F8F8F8",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "8px",
          }}
        >
          <div style={{ height: "32px", backgroundColor: "black" }}></div>
        </div>
        <Menu
          defaultSelectedKeys={`${location.pathname.slice(1)}`}
          defaultOpenKeys={["ipsn"]}
          mode="inline"
          style={{ margin: "30px 0", backgroundColor: "#F8F8F8" }}
          items={items}
          onSelect={(item) => {
            navigate(item.key);
          }}
        />
      </Sider>
      <Layout
        style={{
          backgroundColor: "#F8F8F8",
          marginLeft: 200,
        }}
      >
        <Header style={headerStyle}>
          <Row justify="space-between">
            <Col></Col>
            <Col>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Space>
                  <Avatar icon={<UserOutlined />} />
                  <Typography.Title
                    level={5}
                    style={{ margin: 0 }}
                  ></Typography.Title>
                  <DownOutlined />
                </Space>
              </Dropdown>
            </Col>
          </Row>
        </Header>
        <Content style={contentStyle}>
          <div
            style={{
              minHeight: 360,
              padding: 24,
            }}
          >
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
