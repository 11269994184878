import { Column } from "@ant-design/plots";

const StackedColumn = ({ data, xAxis, yAxis }) => {
  const config = {
    data: data,
    isStack: true,
    xField: xAxis,
    yField: yAxis,
    seriesField: "type",
    annotations: [{ type: "shape", offsetY: 10 }],
    label: false,
    colorField: "type",
    // columnStyle: {
    //   radius: [50, 50, 0, 0],
    // },
    // intervalPadding: 30,
    legend: {
      layout: "horizontal",
      position: "top-left",
      offsetY: -8,
    },
    columnBackground: {
      style: {
        fill: "rgba(0,0,0,0.1)",
      },
    },
    // padding: 0,
    color: ({ type }) => {
      if (type === "0 - 50%") {
        return "#67c04f";
      } else if (type === "50 - 75%") {
        return "#eff436";
      } else {
        return "#f43636";
      }
    },
  };
  return data ? (
    <>
      <Column {...config} />
    </>
  ) : null;
};

export default StackedColumn;
