import axios from "axios";
import * as React from "react";
import { Navigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { message } from "antd";

const url = process.env.REACT_APP_NODE_API_URL;

const SvgComponent = ({ date: d }) => {
  const [date, setDate] = useState(d);
  const { data, isError, error } = useQuery(
    ["ip-network-utilisasi", date],
    async () => {
      const params = new URLSearchParams({
        year: date.year(),
        month: date.month() + 1,
        day: date.get("date"),
      });
      return await axios.get(`${url}/ip-network-utilisasi?${params}`, {
        withCredentials: true,
      });
    },
    {
      select: ({ data }) => {
        if (!data) {
          return { info: {}, wecareach: {} };
        }
        const info = data.info.reduce((acc, curr) => {
          const { name, _id, ...rest } = curr;
          return { ...acc, [name]: { ...rest } };
        }, {});
        const wecareach = data.wecareach.reduce((acc, curr) => {
          const { name, pcg } = curr;
          return { ...acc, [name]: pcg };
        }, {});
        return { info, wecareach };
      },
      onError: (error) => {
        message.error(error.message);
      },
    }
  );

  useEffect(() => {
    setDate(d);
  }, [d]);

  if (isError && error.response.status === 401) {
    // console.log("called");
    return <Navigate to="/signin" replace={true} />;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // width="auto"
      // height="80%"
      viewBox="0 0 1250 760"
    >
      <rect
        x={901}
        y={126}
        width={280}
        height={95}
        rx={14.25}
        ry={14.25}
        fill="none"
        stroke="#000"
        pointerEvents="none"
      />
      <path
        d="M953.35 383.43c-27.76-5.84-42.86 9.54-40.43 23.32v.53c-21.92 4.24-16.08 32.33-5.35 32.33h.96c-2.92 13.26 22.9 26.5 37.03 19.62l.97-1.07c4.88 12.2 23.38 13.79 40.91 14.32 15.59.52 24.36-.53 31.18-7.96h.97c24.84 2.12 36.05-16.42 30.68-29.68l1.47-.53c8.28-2.11 9.26-23.33-5.35-26.51l.48-1.05c6.33-13.78-8.77-26.5-29.22-24.39l-1.47-.52c-14.61-14.84-56.98-12.2-61.85 2.11Z"
        fill="#6ba9ce"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 24,
              height: 1,
              paddingTop: 425,
              marginLeft: 927,
            }}
          >
            <div
              data-drawio-colors="color: #1A1A1A;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#1a1a1a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#fff">{"P"}</font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={939}
          y={430}
          fill="#1A1A1A"
          fontFamily="Helvetica"
          fontSize={16}
          textAnchor="middle"
        >
          {"P"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 24,
              height: 1,
              paddingTop: 425,
              marginLeft: 1005,
            }}
          >
            <div
              data-drawio-colors="color: #1A1A1A;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#1a1a1a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#fff">{"E"}</font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={1017}
          y={430}
          fill="#1A1A1A"
          fontFamily="Helvetica"
          fontSize={16}
          textAnchor="middle"
        >
          {"E"}
        </text>
      </switch>
      <circle
        cx={976}
        cy={425.41}
        fill="#65c97a"
        transform="rotate(-45 976 425.41)"
        pointerEvents="none"
        r={15}
      />
      <path
        d="m988.077 426.386-5.303.056.021 2.2-4.836-3.183 4.773-3.288.02 2.185 5.304-.056Zm-11.108.912.056 5.303 2.2-.02-3.168 4.836-3.288-4.773 2.177-.029-.05-5.296Zm-13.025-2.927 5.31-.05-.035-2.199 4.844 3.175-4.773 3.288-.022-2.185-5.303.057Zm10.96-.835-.05-5.296-2.199.021 3.168-4.836 3.288 4.773-2.178.014.05 5.31Zm-9.51-8.733c-5.855 5.855-5.855 15.359 0 21.214 5.854 5.854 15.358 5.854 21.213 0 5.854-5.855 5.854-15.359 0-21.214-5.855-5.854-15.359-5.854-21.214 0Zm.268.27c5.714-5.714 14.962-5.714 20.676 0 5.713 5.713 5.713 14.961 0 20.675-5.714 5.713-14.962 5.713-20.676 0-5.713-5.714-5.713-14.962 0-20.676Z"
        fill="#fff"
        pointerEvents="none"
      />
      <circle
        cx={1056}
        cy={423.91}
        fill="#65c97a"
        transform="rotate(-45 1056 423.91)"
        pointerEvents="none"
        r={15}
      />
      <path
        d="m1068.077 424.886-5.303.056.021 2.2-4.836-3.183 4.773-3.288.02 2.185 5.304-.056Zm-11.108.912.056 5.303 2.2-.02-3.168 4.836-3.288-4.773 2.177-.029-.05-5.296Zm-13.025-2.927 5.31-.05-.035-2.199 4.844 3.175-4.773 3.288-.022-2.185-5.303.057Zm10.96-.835-.05-5.296-2.199.021 3.168-4.836 3.288 4.773-2.178.014.05 5.31Zm-9.51-8.733c-5.855 5.855-5.855 15.359 0 21.214 5.854 5.854 15.358 5.854 21.213 0 5.854-5.855 5.854-15.359 0-21.214-5.855-5.854-15.359-5.854-21.214 0Zm.268.27c5.714-5.714 14.962-5.714 20.676 0 5.713 5.713 5.713 14.961 0 20.675-5.714 5.713-14.962 5.713-20.676 0-5.713-5.714-5.713-14.962 0-20.676Z"
        fill="#fff"
        pointerEvents="none"
      />
      <path
        d="m1076.22 424.9-15.22-.75"
        fill="none"
        stroke="#1a1a1a"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeDasharray="9 9"
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 48,
              height: 1,
              paddingTop: 452,
              marginLeft: 883,
            }}
          >
            <div
              data-drawio-colors="color: #FFB366;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#ffb366",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font size={1}>
                  <b
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {"SPEEDY"}
                  </b>
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={883}
          y={457}
          fill="#FFB366"
          fontFamily="Helvetica"
          fontSize={16}
        >
          {"SPEEDY"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 77,
              height: 1,
              paddingTop: 395,
              marginLeft: 944,
            }}
          >
            <div
              data-drawio-colors="color: #FFB366;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#ffb366",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font size={1}>
                  <b
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {"TERRACORE"}
                  </b>
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={944}
          y={400}
          fill="#FFB366"
          fontFamily="Helvetica"
          fontSize={16}
        >
          {"TERRACORE"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 62,
              height: 1,
              paddingTop: 451,
              marginLeft: 1026,
            }}
          >
            <div
              data-drawio-colors="color: #FFB366;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#ffb366",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font size={1}>
                  <b
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {"GATEWAY"}
                  </b>
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={1026}
          y={456}
          fill="#FFB366"
          fontFamily="Helvetica"
          fontSize={16}
        >
          {"GATEWAY"}
        </text>
      </switch>
      <rect
        x={301}
        y={124.59}
        width={280}
        height={95}
        rx={14.25}
        ry={14.25}
        fill="none"
        stroke="#000"
        pointerEvents="none"
      />
      <rect
        x={321}
        y={109.59}
        width={230}
        height={30}
        rx={15}
        ry={15}
        fill="#1a1a1a"
        stroke="#000"
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 148,
              height: 1,
              paddingTop: 125,
              marginLeft: 362,
            }}
          >
            <div
              data-drawio-colors="color: rgb(0, 0, 0);"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 12,
                  fontFamily: "Helvetica",
                  color: "#000",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <b>
                  <font
                    style={{
                      fontSize: 16,
                    }}
                    color="#fff"
                  >
                    {"BRAS - PE"}
                  </font>
                </b>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={436}
          y={128}
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"BRAS - PE"}
        </text>
      </switch>
      <path
        d="m441 219.59.09 91.5h400L841 346"
        fill="none"
        stroke="#000"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 167,
              marginLeft: 328,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">{"75% :"}</font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={328} y={172} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"75% :"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 197,
              marginLeft: 328,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">{"75% :"}</font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={328} y={202} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"75% :"}
        </text>
      </switch>
      <path
        d="m321 161.09-6 6 6 6M315 191.09l6 6-6 6"
        fill="none"
        stroke="#1a1a1a"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 68,
              height: 1,
              paddingTop: 180,
              marginLeft: 482,
            }}
          >
            <div
              data-drawio-colors="color: #1A1A1A;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 24,
                  fontFamily: "Helvetica",
                  color: "#1a1a1a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                {data?.info["bras-pehsi"]?.pcg ?? 0}%
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={516}
          y={188}
          fill="#1A1A1A"
          fontFamily="Helvetica"
          fontSize={24}
          textAnchor="middle"
        >
          {"pcg3"}
        </text>
      </switch>
      <rect
        x={601}
        y={126}
        width={280}
        height={95}
        rx={14.25}
        ry={14.25}
        fill="none"
        stroke="#000"
        pointerEvents="none"
      />
      <rect
        x={621}
        y={111}
        width={230}
        height={30}
        rx={15}
        ry={15}
        fill="#1a1a1a"
        stroke="#000"
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 148,
              height: 1,
              paddingTop: 126,
              marginLeft: 662,
            }}
          >
            <div
              data-drawio-colors="color: rgb(0, 0, 0);"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 12,
                  fontFamily: "Helvetica",
                  color: "#000",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <b>
                  <font
                    style={{
                      fontSize: 16,
                    }}
                    color="#fff"
                  >
                    {"NAT"}
                  </font>
                </b>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={736}
          y={130}
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"NAT"}
        </text>
      </switch>
      <path
        d="m741 221 .09 45h190l-.09 45"
        fill="none"
        stroke="#000"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 169,
              marginLeft: 628,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">{"75% :"}</font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={628} y={174} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"75% :"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 199,
              marginLeft: 628,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">{"75% :"}</font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={628} y={204} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"75% :"}
        </text>
      </switch>
      <path
        d="m621 162.5-6 6 6 6M615 192.5l6 6-6 6"
        fill="none"
        stroke="#1a1a1a"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 68,
              height: 1,
              paddingTop: 182,
              marginLeft: 782,
            }}
          >
            <div
              data-drawio-colors="color: #1A1A1A;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 24,
                  fontFamily: "Helvetica",
                  color: "#1a1a1a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                {data?.info["okupansi-nat"]?.pcg ?? 0}%
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={816}
          y={189}
          fill="#1A1A1A"
          fontFamily="Helvetica"
          fontSize={24}
          textAnchor="middle"
        >
          {"pcg5"}
        </text>
      </switch>
      <rect
        x={601}
        y={1}
        width={280}
        height={65.17}
        rx={9.78}
        ry={9.78}
        fill="none"
        stroke="#000"
        strokeWidth={3}
        strokeDasharray="9 9"
        pointerEvents="none"
      />
      <path
        d="m690.32 66.17.28-65.3M601 33.59l-165 76"
        fill="none"
        stroke="#000"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeDasharray="9 9"
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 58,
              height: 1,
              paddingTop: 34,
              marginLeft: 616,
            }}
          >
            <div
              data-drawio-colors="color: #1A1A1A;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#1a1a1a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <b>
                  <font color="#39f">
                    {"WeCare"}
                    <br />
                    {"Ach"}
                  </font>
                </b>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={645}
          y={38}
          fill="#1A1A1A"
          fontFamily="Helvetica"
          fontSize={16}
          textAnchor="middle"
        >
          {"WeCare..."}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 98,
              height: 1,
              paddingTop: 34,
              marginLeft: 702,
            }}
          >
            <div
              data-drawio-colors="color: #1A1A1A;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#1a1a1a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <b>
                  <font color="#39f">
                    {"IP"}
                    <br />
                    {"Broadband"}
                  </font>
                </b>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={751}
          y={38}
          fill="#1A1A1A"
          fontFamily="Helvetica"
          fontSize={16}
          textAnchor="middle"
        >
          {"IP..."}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 58,
              height: 1,
              paddingTop: 34,
              marginLeft: 812,
            }}
          >
            <div
              data-drawio-colors="color: #1A1A1A;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#1a1a1a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                {data?.wecareach["ip-broadband"] ?? 0}%
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={841}
          y={38}
          fill="#1A1A1A"
          fontFamily="Helvetica"
          fontSize={16}
          textAnchor="middle"
        >
          {"s3"}
        </text>
      </switch>
      <path
        d="M25.4 438.9a4.76 4.76 0 0 1-3.22-1.67c-.8-.96-1.18-2.19-1.05-3.41v-57.34c.2-2.36 2.05-4.28 4.46-4.62h84.44c2.78-.11 5.16 1.93 5.41 4.62l-.09 58.26c-.56 2.46-2.74 4.24-5.32 4.35h-29.6l-1.33 5.27c-.31 1.21-.1 2.5.58 3.56a4.707 4.707 0 0 0 3.03 2.08c2.86.95 5.89 1.36 8.92 1.2h5.5v5.55H35.55v-5.55h6.55c3.43.02 6.83-.54 10.06-1.66 2.53-.6 4.1-3.08 3.51-5.55l-.86-4.9Zm2.75-6.66h79.98v-53.26H28.15Z"
        fill="#65c97a"
        pointerEvents="none"
      />
      <path
        d="m1071.07 423.92 29.93-.32"
        fill="none"
        stroke="#65c97a"
        strokeWidth={3}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <path
        d="M549.22 383.81c-30.38-5.84-46.89 9.54-44.24 23.32v.53c-23.98 4.24-17.58 32.33-5.85 32.33h1.06c-3.2 13.26 25.05 26.5 40.51 19.62l1.05-1.07c5.34 12.2 25.58 13.79 44.77 14.32 17.06.52 26.65-.53 34.12-7.96h1.05c27.18 2.12 39.44-16.42 33.58-29.68l1.59-.53c9.07-2.11 10.14-23.33-5.85-26.51l.53-1.05c6.92-13.78-9.59-26.5-31.98-24.39l-1.59-.52c-15.99-14.84-62.36-12.2-67.68 2.11Z"
        fill="#6ba9ce"
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 74,
              height: 1,
              paddingTop: 422,
              marginLeft: 546,
            }}
          >
            <div
              data-drawio-colors="color: #1A1A1A;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#1a1a1a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#fff">{"METRO E"}</font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={583}
          y={427}
          fill="#1A1A1A"
          fontFamily="Helvetica"
          fontSize={16}
          textAnchor="middle"
        >
          {"METRO E"}
        </text>
      </switch>
      <path fill="#65c97a" pointerEvents="none" d="M649 407.38h40v37.93h-40z" />
      <image
        x={648.5}
        y={406.88}
        width={37.93}
        height={37.93}
        xlinkHref="https://cdn0.iconfinder.com/data/icons/phosphor-fill-vol-4/256/shuffle-fill-128.png"
        preserveAspectRatio="none"
        transform="matrix(-1 0 0 1 1335.92 0)"
        pointerEvents="none"
      />
      <image
        x={648.5}
        y={406.88}
        width={37.93}
        height={37.93}
        xlinkHref="https://cdn0.iconfinder.com/data/icons/phosphor-fill-vol-4/256/shuffle-fill-128.png"
        preserveAspectRatio="none"
        pointerEvents="none"
      />
      <path
        d="M701 531V251"
        fill="none"
        stroke="#e1d5e7"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeDasharray="6 6"
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 40,
              height: 1,
              paddingTop: 452,
              marginLeft: 651,
            }}
          >
            <div
              data-drawio-colors="color: #FFB366;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#ffb366",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font size={1}>
                  <b
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {"BRAS"}
                  </b>
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={651}
          y={457}
          fill="#FFB366"
          fontFamily="Helvetica"
          fontSize={16}
        >
          {"BRAS"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 38,
              height: 1,
              paddingTop: 450,
              marginLeft: 467,
            }}
          >
            <div
              data-drawio-colors="color: #FFB366;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#ffb366",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font size={1}>
                  <b
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {"AKSES"}
                  </b>
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={467}
          y={455}
          fill="#FFB366"
          fontFamily="Helvetica"
          fontSize={16}
        >
          {"AKSES"}
        </text>
      </switch>
      <rect
        x={1}
        y={4.59}
        width={280}
        height={85}
        rx={12.75}
        ry={12.75}
        fill="none"
        stroke="#000"
        strokeWidth={3}
        strokeDasharray="9 9"
        pointerEvents="none"
      />
      <path
        d="m90.32 89.59.28-85.17M91 49.59l189.72.3"
        fill="none"
        stroke="#000"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeDasharray="9 9"
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 58,
              height: 1,
              paddingTop: 47,
              marginLeft: 16,
            }}
          >
            <div
              data-drawio-colors="color: #3399FF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#39f",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  fontWeight: 700,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                {"WeCare"}
                <br />
                {"Ach"}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={45}
          y={51}
          fill="#39F"
          fontFamily="Helvetica"
          fontSize={16}
          textAnchor="middle"
          fontWeight="bold"
        >
          {"WeCare..."}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 88,
              height: 1,
              paddingTop: 25,
              marginLeft: 103,
            }}
          >
            <div
              data-drawio-colors="color: #3399FF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#39f",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  fontWeight: 700,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                {"All Metro"}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={103}
          y={29}
          fill="#39F"
          fontFamily="Helvetica"
          fontSize={16}
          fontWeight="bold"
        >
          {"All Metro"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 88,
              height: 1,
              paddingTop: 70,
              marginLeft: 103,
            }}
          >
            <div
              data-drawio-colors="color: #3399FF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#39f",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  fontWeight: 700,
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                {"Metro POP"}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={103}
          y={74}
          fill="#39F"
          fontFamily="Helvetica"
          fontSize={16}
          fontWeight="bold"
        >
          {"Metro POP"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-end",
              width: 58,
              height: 1,
              paddingTop: 27,
              marginLeft: 201,
            }}
          >
            <div
              data-drawio-colors="color: #1A1A1A;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "right",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#1a1a1a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                {data?.wecareach["all-metro"] ?? 0}%
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={259}
          y={32}
          fill="#1A1A1A"
          fontFamily="Helvetica"
          fontSize={16}
          textAnchor="end"
        >
          {"s1"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-end",
              width: 58,
              height: 1,
              paddingTop: 70,
              marginLeft: 201,
            }}
          >
            <div
              data-drawio-colors="color: #1A1A1A;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "right",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#1a1a1a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                {data?.wecareach["metro-pop"] ?? 0}%
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={259}
          y={74}
          fill="#1A1A1A"
          fontFamily="Helvetica"
          fontSize={16}
          textAnchor="end"
        >
          {"s2"}
        </text>
      </switch>
      <circle
        cx={763}
        cy={426.18}
        fill="#65c97a"
        transform="rotate(-45 763 426.18)"
        pointerEvents="none"
        r={25}
      />
      <path
        d="m783.124 427.806-8.839.085.036 3.67-8.061-5.29 7.962-5.486.035 3.641 8.832-.092Zm-18.505 1.52.092 8.832 3.67-.035-5.29 8.061-5.48-7.955 3.628-.035-.077-8.832Zm-21.708-4.878 8.839-.085-.042-3.663 8.068 5.282-7.962 5.487-.029-3.634-8.839.084Zm18.265-1.38-.085-8.838-3.67.035 5.29-8.06 5.48 7.954-3.635.028.092 8.846Zm-15.854-14.566c-9.758 9.758-9.758 25.598 0 35.356 9.758 9.758 25.598 9.758 35.356 0 9.758-9.758 9.758-25.598 0-35.356-9.758-9.758-25.598-9.758-35.356 0Zm.446.446c9.517-9.518 24.947-9.518 34.464 0 9.518 9.517 9.518 24.947 0 34.464-9.517 9.518-24.947 9.518-34.464 0-9.518-9.517-9.518-24.947 0-34.464Z"
        fill="#fff"
        pointerEvents="none"
      />
      <path
        d="m763.02 401.06.21-25.15"
        fill="none"
        stroke="#b3b3b3"
        strokeWidth={3}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <circle
        cx={846}
        cy={426.18}
        fill="#65c97a"
        transform="rotate(-45 846 426.18)"
        pointerEvents="none"
        r={15}
      />
      <path
        d="m858.077 427.156-5.303.056.021 2.2-4.836-3.183 4.773-3.288.02 2.185 5.304-.056Zm-11.108.912.056 5.303 2.2-.02-3.168 4.836-3.288-4.773 2.177-.029-.05-5.296Zm-13.025-2.927 5.31-.05-.035-2.199 4.844 3.175-4.773 3.288-.022-2.185-5.303.057Zm10.96-.835-.05-5.296-2.199.021 3.168-4.836 3.288 4.773-2.178.014.05 5.31Zm-9.51-8.733c-5.855 5.855-5.855 15.359 0 21.214 5.854 5.854 15.358 5.854 21.213 0 5.854-5.855 5.854-15.359 0-21.214-5.855-5.854-15.359-5.854-21.214 0Zm.268.27c5.714-5.714 14.962-5.714 20.676 0 5.713 5.713 5.713 14.961 0 20.675-5.714 5.713-14.962 5.713-20.676 0-5.713-5.714-5.713-14.962 0-20.676Z"
        fill="#fff"
        pointerEvents="none"
      />
      <path
        d="m788.12 426.2 42.82-.02"
        fill="none"
        stroke="#65c97a"
        strokeWidth={3}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <path
        d="m916.68 425.04-13.78.58"
        fill="none"
        stroke="#1a1a1a"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeDasharray="9 9"
        pointerEvents="none"
      />
      <circle
        cx={931}
        cy={331}
        fill="#1a1a1a"
        stroke="#000"
        pointerEvents="none"
        r={20}
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 8,
              height: 1,
              paddingTop: 331,
              marginLeft: 927,
            }}
          >
            <div
              data-drawio-colors="color: rgb(0, 0, 0);"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 12,
                  fontFamily: "Helvetica",
                  color: "#000",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <b>
                  <font
                    style={{
                      fontSize: 15,
                    }}
                    color="#fff"
                  >
                    {"5"}
                  </font>
                </b>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={931}
          y={335}
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"5"}
        </text>
      </switch>
      <rect
        x={1}
        y={124.59}
        width={280}
        height={95}
        rx={14.25}
        ry={14.25}
        fill="none"
        stroke="#000"
        pointerEvents="none"
      />
      <rect
        x={21}
        y={109.59}
        width={230}
        height={30}
        rx={15}
        ry={15}
        fill="#1a1a1a"
        stroke="#000"
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 148,
              height: 1,
              paddingTop: 125,
              marginLeft: 62,
            }}
          >
            <div
              data-drawio-colors="color: rgb(0, 0, 0);"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 12,
                  fontFamily: "Helvetica",
                  color: "#000",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <b>
                  <font
                    style={{
                      fontSize: 16,
                    }}
                    color="#fff"
                  >
                    {"METRO - METRO"}
                  </font>
                </b>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={136}
          y={128}
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"METRO - METRO"}
        </text>
      </switch>
      <path
        d="m141 219.59.09 53.14h440L581 326"
        fill="none"
        stroke="#000"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 167,
              marginLeft: 28,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">{"50% :"}</font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={28} y={172} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"50% :"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 197,
              marginLeft: 28,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">{"50% :"}</font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={28} y={202} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"50% :"}
        </text>
      </switch>
      <path
        d="m21 161.09-6 6 6 6M15 191.09l6 6-6 6"
        fill="none"
        stroke="#1a1a1a"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 68,
              height: 1,
              paddingTop: 180,
              marginLeft: 182,
            }}
          >
            <div
              data-drawio-colors="color: #1A1A1A;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 24,
                  fontFamily: "Helvetica",
                  color: "#1a1a1a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                {data?.info["metro-link"]?.pcg ?? 0}%
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={216}
          y={187}
          fill="#1A1A1A"
          fontFamily="Helvetica"
          fontSize={24}
          textAnchor="middle"
        >
          {"pcg1"}
        </text>
      </switch>
      <path
        d="M367.07 395.38c-1.69 0-3.07 1.37-3.07 3.05v43.9c0 1.68 1.38 3.05 3.07 3.05h43.86c1.69 0 3.07-1.37 3.07-3.05v-43.9c0-1.68-1.38-3.05-3.07-3.05Z"
        fill="#65c97a"
        pointerEvents="none"
      />
      <path
        d="m389.03 399.98-4.19 5.99h2.78v6.37c-1.22.21-2.38.69-3.39 1.42l-4.54-4.49 1.96-1.97-7.19-1.31 1.33 7.19 1.96-1.97 4.54 4.5c-.69.98-1.16 2.11-1.36 3.29h-6.4v-2.76l-6.03 4.11 6.03 4.17v-2.77h6.39c.21 1.2.68 2.33 1.39 3.32l-4.53 4.51-1.97-1.95-1.3 7.19 7.19-1.34-1.97-1.95 4.52-4.51a8.19 8.19 0 0 0 3.37 1.39v6.38h-2.78l4.19 5.99 4.13-5.99h-2.78v-6.37c1.21-.21 2.35-.68 3.35-1.39l4.54 4.5-1.96 1.96 7.23 1.28-1.36-7.15-1.96 1.96-4.54-4.49c.71-.99 1.19-2.14 1.39-3.34h6.4v2.77l6.03-4.17-6.03-4.11V419h-6.39c-.21-1.21-.7-2.35-1.42-3.35l4.53-4.51 1.96 1.96 1.34-7.16-7.22 1.3 1.96 1.96-4.52 4.51c-.99-.7-2.13-1.16-3.33-1.36v-6.38h2.78Zm-21.96-4.6c-1.69 0-3.07 1.37-3.07 3.05v43.9c0 1.68 1.38 3.05 3.07 3.05h43.86c1.69 0 3.07-1.37 3.07-3.05v-43.9c0-1.68-1.38-3.05-3.07-3.05Zm0 1.38h43.86c.95 0 1.69.73 1.69 1.67v43.9c0 .94-.74 1.67-1.69 1.67h-43.86c-.95 0-1.68-.73-1.68-1.67v-43.9c0-.94.73-1.67 1.68-1.67Z"
        fill="#fff"
        pointerEvents="none"
      />
      <path fill="#65c97a" pointerEvents="none" d="M461 402.53h40v37.93h-40z" />
      <image
        x={460.5}
        y={402.03}
        width={37.93}
        height={37.93}
        xlinkHref="https://cdn0.iconfinder.com/data/icons/phosphor-fill-vol-4/256/shuffle-fill-128.png"
        preserveAspectRatio="none"
        transform="matrix(-1 0 0 1 959.92 0)"
        pointerEvents="none"
      />
      <image
        x={460.5}
        y={402.03}
        width={37.93}
        height={37.93}
        xlinkHref="https://cdn0.iconfinder.com/data/icons/phosphor-fill-vol-4/256/shuffle-fill-128.png"
        preserveAspectRatio="none"
        pointerEvents="none"
      />
      <path
        d="m414 420.77 47 .72M686.93 426.3l50.97-.12"
        fill="none"
        stroke="#65c97a"
        strokeWidth={3}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <path
        d="M274 405.91h50v-7.29l20 10.87-20 10.88v-7.29h-50v-3.59ZM344 434.82h-50v7.29l-20-10.87 20-10.88v7.29h50v3.59Z"
        fill="#65c97a"
        stroke="#fff"
        strokeWidth={0.1}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <path
        d="M254.5 436.5v-15.57h-63.64v15.57h63.64Zm-63.64 0v-15.57h63.64l-16.04-11.43H177.5v12.99l13.36 14.01Zm0-15.57-12.83-11.43"
        fill="#65c97a"
        stroke="#fff"
        strokeWidth={2}
        strokeLinejoin="round"
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <path
        d="M243.27 431.3c-1.07 0-2.13-1.03-2.13-2.07 0-1.04 1.06-2.07 2.13-2.07 1.08 0 2.13 1.03 2.13 2.07 0 1.04-1.05 2.07-2.13 2.07Zm-10.15 0c-1.08 0-2.15-1.03-2.15-2.07 0-1.04 1.07-2.07 2.15-2.07 1.06 0 2.13 1.03 2.13 2.07 0 1.04-1.07 2.07-2.13 2.07Zm-10.17 0c-1.06 0-2.14-1.03-2.14-2.07 0-1.04 1.08-2.07 2.14-2.07 1.07 0 2.15 1.03 2.15 2.07 0 1.04-1.08 2.07-2.15 2.07Zm-10.16 0c-1.07 0-2.13-1.03-2.13-2.07 0-1.04 1.06-2.07 2.13-2.07 1.08 0 2.14 1.03 2.14 2.07 0 1.04-1.06 2.07-2.14 2.07Zm-10.15 0c-1.08 0-2.15-1.03-2.15-2.07 0-1.04 1.07-2.07 2.15-2.07 1.06 0 2.13 1.03 2.13 2.07 0 1.04-1.07 2.07-2.13 2.07Z"
        fill="#fff"
        pointerEvents="none"
      />
      <path
        d="m115.16 425.13 62.34-1.32"
        fill="none"
        stroke="#65c97a"
        strokeWidth={3}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <path
        d="M1175.36 374.94c7.23 2.43 12.47 5.99 16.88 10.05-2.51 1.33-5.19 2.64-8.3 3.8-3.14-7.08-5.89-10.83-8.58-13.85Zm10.88 18.94c3.79-1.46 7.34-3.13 10.64-5.07 7.7 8.27 9.44 13.83 11.46 19.11-5.44 3.5-11.15 6.5-17.49 8.33-.67-6.77-1.65-13.8-4.61-22.37Zm4.73 27.67c7.23-2.04 13.38-4.75 18.46-8.1 1.57 9.35.88 14.97-.2 19.91-8.19 5.78-14.64 8.27-20.95 10.53.87-4.75 1.68-2.58 2.69-22.34Zm-4.55 28.93c5.8-1.71 11.71-3.57 20.26-8.72-7.57 16.97-20.62 25.69-32.03 29.43 4.16-4.39 8.23-9.78 11.77-20.71Zm-6.8 1.64c-5.6 14-13.66 20.4-20.62 21.88v-19.24c7.19-.19 14.08-1.01 20.62-2.64Zm-20.67-2.59v-22.38c7.03.17 16.21-1.33 26.13-3.42.02 9.5-1.46 16.19-3.09 22.61-9.27 2.36-16.41 2.98-23.04 3.19Zm.34-28v-22.71c7.34-.34 14.53-1.29 21.49-3.26 2.37 6.57 3.67 14.32 4.59 22.43-8.01 2.19-16.79 3.25-26.08 3.54Zm-.58-49.78h2.26c5.19 1.63 8.84 5.47 10.95 7.96 3.15 3.78 4.9 7.24 6.55 10.68-5.3 1.42-11.46 2.7-19.76 3Zm-5.46 21.91c-6.58-.27-13.05-1.28-19.46-3.01 2.15-4.94 4.91-9.48 8.71-13.31 2.31-2.15 4.81-4.18 8.49-5.32h2.26Zm.29 27.91c-9.87-.35-18.49-1.6-26.1-3.56.51-6.53.69-12.71 4.59-22.42 8.57 2.53 18.51 3.25 21.53 3.18Zm-.29 5.35.02 22.42c-7.57.19-15.11-1.04-22.68-2.87-2.39-7.98-3.42-15.61-3.38-22.97 8.55 2.21 17.24 3.25 26.04 3.42Zm0 28.14v19.19c-4.06-.82-7.44-2.91-10.41-5.74-4.73-4.56-7.9-10.07-10.28-15.6 6.88 1.3 13.75 2.28 20.69 2.15Zm-27.34-4.14c3.88 11.17 8 15.9 12.14 20.4-18.07-6.42-26.89-18.12-31.77-28.08 6.43 3.14 12.85 6.29 19.63 7.68Zm-22.6-15.92c-1.82-6.12-2.31-13.15-.79-21.55 5.24 3.04 11.11 5.91 18.85 8.36-.02 8.23.83 15.79 2.62 22.65-5.69-1.5-12.2-4.14-20.68-9.46Zm.91-27.04c2.38-7.5 6.36-13.77 11.51-19.15 3.72 2.36 7.12 3.61 10.57 5.11-2.87 7.37-4.12 15.58-4.57 22.42-5.53-1.86-11.06-3.8-17.51-8.38Zm15.47-23.23c5.89-4.66 11.62-7.81 17.24-9.77-2.87 3.19-5.8 7.26-8.89 13.55-3.13-1.26-5.98-2.51-8.35-3.78Z"
        fill="#65c97a"
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 78,
              height: 1,
              paddingTop: 429,
              marginLeft: 1117,
            }}
          >
            <div
              data-drawio-colors="color: #65C97A; background-color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#65c97a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  backgroundColor: "#fff",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <span>
                  <font
                    style={{
                      fontSize: 25,
                    }}
                  >
                    {"Internet"}
                  </font>
                </span>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={1156}
          y={434}
          fill="#65C97A"
          fontFamily="Helvetica"
          fontSize={16}
          textAnchor="middle"
        >
          {"Internet"}
        </text>
      </switch>
      <path
        d="M151 531V251"
        fill="none"
        stroke="#e1d5e7"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeDasharray="6 6"
        pointerEvents="none"
      />
      <circle
        cx={581}
        cy={346}
        fill="#1a1a1a"
        stroke="#000"
        pointerEvents="none"
        r={20}
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 18,
              height: 1,
              paddingTop: 346,
              marginLeft: 572,
            }}
          >
            <div
              data-drawio-colors="color: rgb(0, 0, 0);"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 12,
                  fontFamily: "Helvetica",
                  color: "#000",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <b>
                  <font
                    style={{
                      fontSize: 15,
                    }}
                    color="#fff"
                  >
                    {"1"}
                  </font>
                </b>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={581}
          y={350}
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"1"}
        </text>
      </switch>
      <path
        d="M421 533.67v-280"
        fill="none"
        stroke="#e1d5e7"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeDasharray="6 6"
        pointerEvents="none"
      />
      <circle
        cx={841}
        cy={366}
        fill="#1a1a1a"
        stroke="#000"
        pointerEvents="none"
        r={20}
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 18,
              height: 1,
              paddingTop: 366,
              marginLeft: 832,
            }}
          >
            <div
              data-drawio-colors="color: rgb(0, 0, 0);"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 12,
                  fontFamily: "Helvetica",
                  color: "#000",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <b>
                  <font
                    style={{
                      fontSize: 15,
                    }}
                    color="#fff"
                  >
                    {"3"}
                  </font>
                </b>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={841}
          y={370}
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"3"}
        </text>
      </switch>
      <rect
        x={921}
        y={111}
        width={230}
        height={30}
        rx={15}
        ry={15}
        fill="#1a1a1a"
        stroke="#000"
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 148,
              height: 1,
              paddingTop: 126,
              marginLeft: 962,
            }}
          >
            <div
              data-drawio-colors="color: rgb(0, 0, 0);"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 12,
                  fontFamily: "Helvetica",
                  color: "#000",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <b>
                  <font
                    style={{
                      fontSize: 16,
                    }}
                    color="#fff"
                  >
                    {"PE - TERA"}
                  </font>
                </b>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={1036}
          y={130}
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"PE - TERA"}
        </text>
      </switch>
      <path
        d="M881 33.59 1036 111"
        fill="none"
        stroke="#000"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeDasharray="9 9"
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 68,
              height: 1,
              paddingTop: 182,
              marginLeft: 1082,
            }}
          >
            <div
              data-drawio-colors="color: #1A1A1A;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 24,
                  fontFamily: "Helvetica",
                  color: "#1a1a1a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                {data?.info["pehsi-tera"]?.pcg ?? 0}%
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={1116}
          y={189}
          fill="#1A1A1A"
          fontFamily="Helvetica"
          fontSize={24}
          textAnchor="middle"
        >
          {"pcg6"}
        </text>
      </switch>
      <path
        d="M881 541V281"
        fill="none"
        stroke="#e1d5e7"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeDasharray="6 6"
        pointerEvents="none"
      />
      <circle
        cx={906}
        cy={425.46}
        fill="#65c97a"
        transform="rotate(-45 906 425.46)"
        pointerEvents="none"
        r={15}
      />
      <path
        d="m918.077 426.436-5.303.056.021 2.2-4.836-3.183 4.773-3.288.02 2.185 5.304-.056Zm-11.108.912.056 5.303 2.2-.02-3.168 4.836-3.288-4.773 2.177-.029-.05-5.296Zm-13.025-2.927 5.31-.05-.035-2.199 4.844 3.175-4.773 3.288-.022-2.185-5.303.057Zm10.96-.835-.05-5.296-2.199.021 3.168-4.836 3.288 4.773-2.178.014.05 5.31Zm-9.51-8.733c-5.855 5.855-5.855 15.359 0 21.214 5.854 5.854 15.358 5.854 21.213 0 5.854-5.855 5.854-15.359 0-21.214-5.855-5.854-15.359-5.854-21.214 0Zm.268.27c5.714-5.714 14.962-5.714 20.676 0 5.713 5.713 5.713 14.961 0 20.675-5.714 5.713-14.962 5.713-20.676 0-5.713-5.714-5.713-14.962 0-20.676Z"
        fill="#fff"
        pointerEvents="none"
      />
      <path
        d="M1081 543.5v-260"
        fill="none"
        stroke="#e1d5e7"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeDasharray="6 6"
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 58,
              height: 1,
              paddingTop: 476,
              marginLeft: 33,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  <b>{"Customer"}</b>
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={33} y={481} fill="#FFF" fontFamily="Helvetica" fontSize={16}>
          {"Customer"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 138,
              height: 1,
              paddingTop: 476,
              marginLeft: 243,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  <b>{"Segment Access"}</b>
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={243} y={481} fill="#FFF" fontFamily="Helvetica" fontSize={16}>
          {"Segment Access"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 108,
              height: 1,
              paddingTop: 489,
              marginLeft: 513,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  <b>{"Aggregation"}</b>
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={513} y={494} fill="#FFF" fontFamily="Helvetica" fontSize={16}>
          {"Aggregation"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 138,
              height: 1,
              paddingTop: 496,
              marginLeft: 1093,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  <b>{"Konten / Global"}</b>
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={1093} y={501} fill="#FFF" fontFamily="Helvetica" fontSize={16}>
          {"Konten / Global"}
        </text>
      </switch>
      <circle
        cx={666}
        cy={494.25}
        fill="#1a1a1a"
        stroke="#000"
        pointerEvents="none"
        r={20}
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 8,
              height: 1,
              paddingTop: 494,
              marginLeft: 662,
            }}
          >
            <div
              data-drawio-colors="color: rgb(0, 0, 0);"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 12,
                  fontFamily: "Helvetica",
                  color: "#000",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <b>
                  <font
                    style={{
                      fontSize: 15,
                    }}
                    color="#fff"
                  >
                    {"2"}
                  </font>
                </b>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={666}
          y={498}
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"2"}
        </text>
      </switch>
      <rect
        x={151}
        y={566}
        width={280}
        height={95}
        rx={14.25}
        ry={14.25}
        fill="none"
        stroke="#000"
        pointerEvents="none"
      />
      <rect
        x={171}
        y={551}
        width={230}
        height={30}
        rx={15}
        ry={15}
        fill="#1a1a1a"
        stroke="#000"
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 148,
              height: 1,
              paddingTop: 566,
              marginLeft: 212,
            }}
          >
            <div
              data-drawio-colors="color: rgb(0, 0, 0);"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 12,
                  fontFamily: "Helvetica",
                  color: "#000",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <b>
                  <font
                    style={{
                      fontSize: 16,
                    }}
                    color="#fff"
                  >
                    {"METRO - BRAS"}
                  </font>
                </b>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={286}
          y={570}
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"METRO - BRAS"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 608,
              marginLeft: 180,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">{"75% :"}</font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={180} y={614} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"75% :"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 639,
              marginLeft: 178,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">{"75% :"}</font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={178} y={644} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"75% :"}
        </text>
      </switch>
      <path
        d="m171 602.5-6 6 6 6M165 632.5l6 6-6 6"
        fill="none"
        stroke="#1a1a1a"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 68,
              height: 1,
              paddingTop: 620,
              marginLeft: 332,
            }}
          >
            <div
              data-drawio-colors="color: #1A1A1A;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 24,
                  fontFamily: "Helvetica",
                  color: "#1a1a1a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                {data?.info["metro-bras"]?.pcg ?? 0}%
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={366}
          y={627}
          fill="#1A1A1A"
          fontFamily="Helvetica"
          fontSize={24}
          textAnchor="middle"
        >
          {"pcg2"}
        </text>
      </switch>
      <path
        d="M666 514.25v14.84H286V551"
        fill="none"
        stroke="#1a1a1a"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <rect
        x={451}
        y={566}
        width={280}
        height={95}
        rx={14.25}
        ry={14.25}
        fill="none"
        stroke="#000"
        pointerEvents="none"
      />
      <rect
        x={471}
        y={551}
        width={230}
        height={30}
        rx={15}
        ry={15}
        fill="#1a1a1a"
        stroke="#000"
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 148,
              height: 1,
              paddingTop: 566,
              marginLeft: 512,
            }}
          >
            <div
              data-drawio-colors="color: rgb(0, 0, 0);"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 12,
                  fontFamily: "Helvetica",
                  color: "#000",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <b>
                  <font
                    style={{
                      fontSize: 16,
                    }}
                    color="#fff"
                  >
                    {"PCEF"}
                  </font>
                </b>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={586}
          y={570}
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"PCEF"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 608,
              marginLeft: 478,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">{"75% :"}</font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={478} y={614} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"75% :"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 638,
              marginLeft: 478,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">{"75% :"}</font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={478} y={643} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"75% :"}
        </text>
      </switch>
      <path
        d="m471 602.5-6 6 6 6M465 632.5l6 6-6 6"
        fill="none"
        stroke="#1a1a1a"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 68,
              height: 1,
              paddingTop: 620,
              marginLeft: 632,
            }}
          >
            <div
              data-drawio-colors="color: #1A1A1A;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 24,
                  fontFamily: "Helvetica",
                  color: "#1a1a1a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                {data?.info["okupansi-pcef"]?.pcg ?? 0}%
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={666}
          y={627}
          fill="#1A1A1A"
          fontFamily="Helvetica"
          fontSize={24}
          textAnchor="middle"
        >
          {"pcg4"}
        </text>
      </switch>
      <rect
        x={856}
        y={566}
        width={370}
        height={95}
        rx={14.25}
        ry={14.25}
        fill="none"
        stroke="#000"
        pointerEvents="none"
      />
      <rect
        x={926}
        y={551}
        width={230}
        height={30}
        rx={15}
        ry={15}
        fill="#1a1a1a"
        stroke="#000"
        pointerEvents="none"
      />
      <path
        d="m861.07 426.19 31.93.18"
        fill="none"
        stroke="#65c97a"
        strokeWidth={3}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 148,
              height: 1,
              paddingTop: 566,
              marginLeft: 967,
            }}
          >
            <div
              data-drawio-colors="color: rgb(0, 0, 0);"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 12,
                  fontFamily: "Helvetica",
                  color: "#000",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <b>
                  <font
                    style={{
                      fontSize: 16,
                    }}
                    color="#fff"
                  >
                    {"IP BACKBONE"}
                  </font>
                </b>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={1041}
          y={570}
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"IP BACKBONE"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 48,
              height: 1,
              paddingTop: 603,
              marginLeft: 883,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">{"50% :"}</font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={883} y={609} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"50% :"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 48,
              height: 1,
              paddingTop: 634,
              marginLeft: 883,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">{"50% :"}</font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={883} y={639} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"50% :"}
        </text>
      </switch>
      <path
        d="m876 597.5-6 6 6 6M870 627.5l6 6-6 6"
        fill="none"
        stroke="#1a1a1a"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 98,
              height: 1,
              paddingTop: 617,
              marginLeft: 1117,
            }}
          >
            <div
              data-drawio-colors="color: #1A1A1A;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 24,
                  fontFamily: "Helvetica",
                  color: "#1a1a1a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                {data?.info["okupansi-ip-backbone"]?.pcg ?? 0}%
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={1166}
          y={624}
          fill="#1A1A1A"
          fontFamily="Helvetica"
          fontSize={24}
          textAnchor="middle"
        >
          {"pcg7"}
        </text>
      </switch>
      <circle
        cx={1051}
        cy={496}
        fill="#1a1a1a"
        stroke="#000"
        pointerEvents="none"
        r={20}
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 8,
              height: 1,
              paddingTop: 496,
              marginLeft: 1047,
            }}
          >
            <div
              data-drawio-colors="color: rgb(0, 0, 0);"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 12,
                  fontFamily: "Helvetica",
                  color: "#000",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <b>
                  <font
                    style={{
                      fontSize: 15,
                    }}
                    color="#fff"
                  >
                    {"7"}
                  </font>
                </b>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={1051}
          y={500}
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"7"}
        </text>
      </switch>
      <rect
        x={901}
        y={681.13}
        width={280}
        height={65.17}
        rx={9.78}
        ry={9.78}
        fill="none"
        stroke="#000"
        strokeWidth={3}
        strokeDasharray="9 9"
        pointerEvents="none"
      />
      <path
        d="m990.32 746.3.28-65.3"
        fill="none"
        stroke="#000"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeDasharray="9 9"
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 58,
              height: 1,
              paddingTop: 714,
              marginLeft: 916,
            }}
          >
            <div
              data-drawio-colors="color: #1A1A1A;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#1a1a1a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <b>
                  <font color="#39f">
                    {"WeCare"}
                    <br />
                    {"Ach"}
                  </font>
                </b>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={945}
          y={719}
          fill="#1A1A1A"
          fontFamily="Helvetica"
          fontSize={16}
          textAnchor="middle"
        >
          {"WeCare..."}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 98,
              height: 1,
              paddingTop: 714,
              marginLeft: 1002,
            }}
          >
            <div
              data-drawio-colors="color: #1A1A1A;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#1a1a1a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <b>
                  <font color="#39f">
                    {"IP"}
                    <br />
                    {"Backbone"}
                  </font>
                </b>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={1051}
          y={719}
          fill="#1A1A1A"
          fontFamily="Helvetica"
          fontSize={16}
          textAnchor="middle"
        >
          {"IP..."}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 58,
              height: 1,
              paddingTop: 714,
              marginLeft: 1112,
            }}
          >
            <div
              data-drawio-colors="color: #1A1A1A;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#1a1a1a",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                {data?.wecareach["ip-backbone"] ?? 0}%
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={1141}
          y={719}
          fill="#1A1A1A"
          fontFamily="Helvetica"
          fontSize={16}
          textAnchor="middle"
        >
          {"s4"}
        </text>
      </switch>
      <path
        d="M1041.09 551v-19.91h10L1051 516"
        fill="none"
        stroke="#1a1a1a"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 28,
              height: 1,
              paddingTop: 446,
              marginLeft: 209,
            }}
          >
            <div
              data-drawio-colors="color: #FFB366;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#ffb366",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font size={1}>
                  <b
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {"ONT"}
                  </b>
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={209}
          y={451}
          fill="#FFB366"
          fontFamily="Helvetica"
          fontSize={16}
        >
          {"ONT"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 28,
              height: 1,
              paddingTop: 458,
              marginLeft: 750,
            }}
          >
            <div
              data-drawio-colors="color: #FFB366;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#ffb366",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font size={1}>
                  <b
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {"BRAS"}
                  </b>
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={750}
          y={463}
          fill="#FFB366"
          fontFamily="Helvetica"
          fontSize={16}
        >
          {"BRAS"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 78,
              height: 1,
              paddingTop: 401,
              marginLeft: 808,
            }}
          >
            <div
              data-drawio-colors="color: #FFB366;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#ffb366",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font size={1}>
                  <b
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {"REDIRECTOR"}
                  </b>
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={808}
          y={405}
          fill="#FFB366"
          fontFamily="Helvetica"
          fontSize={16}
        >
          {"REDIRECTOR"}
        </text>
      </switch>
      <circle
        cx={741}
        cy={501}
        fill="#1a1a1a"
        stroke="#000"
        pointerEvents="none"
        r={20}
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 8,
              height: 1,
              paddingTop: 501,
              marginLeft: 737,
            }}
          >
            <div
              data-drawio-colors="color: rgb(0, 0, 0);"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 12,
                  fontFamily: "Helvetica",
                  color: "#000",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <b>
                  <font
                    style={{
                      fontSize: 15,
                    }}
                    color="#fff"
                  >
                    {"4"}
                  </font>
                </b>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={741}
          y={505}
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"4"}
        </text>
      </switch>
      <path
        d="m741 521 .09 18.09H586V551"
        fill="none"
        stroke="#1a1a1a"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 28,
              height: 1,
              paddingTop: 452,
              marginLeft: 376,
            }}
          >
            <div
              data-drawio-colors="color: #FFB366;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#ffb366",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font size={1}>
                  <b
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {"OLT"}
                  </b>
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={376}
          y={456}
          fill="#FFB366"
          fontFamily="Helvetica"
          fontSize={16}
        >
          {"OLT"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 167,
              marginLeft: 83,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  {data?.info["metro-link"]?.ltb ?? 0}
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={83} y={172} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"lt t1"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 195,
              marginLeft: 83,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  {data?.info["metro-link"]?.gtb ?? 0}
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={83} y={200} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"gt t1"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 610,
              marginLeft: 235,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  {data?.info["metro-bras"]?.ltb ?? 0}
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={235} y={616} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"lt t2"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 637,
              marginLeft: 235,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  {data?.info["metro-bras"]?.gtb ?? 0}
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={235} y={642} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"gt t2"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 608,
              marginLeft: 533,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  {data?.info["okupansi-pcef"]?.ltb ?? 0}
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={533} y={614} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"lt t4"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 637,
              marginLeft: 533,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  {data?.info["okupansi-pcef"]?.gtb ?? 0}
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={533} y={642} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"gt t4"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 167,
              marginLeft: 383,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  {data?.info["bras-pehsi"]?.ltb ?? 0}
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={383} y={172} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"lt t3"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 197,
              marginLeft: 383,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  {data?.info["bras-pehsi"]?.gtb ?? 0}{" "}
                </font>{" "}
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={383} y={202} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"gt t3"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 169,
              marginLeft: 685,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  {data?.info["okupansi-nat"]?.ltb ?? 0}
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={685} y={174} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"lt t5"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 197,
              marginLeft: 685,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  {data?.info["okupansi-nat"]?.gtb ?? 0}
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={685} y={202} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"gt t5"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 198,
              marginLeft: 928,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">{"75% :"}</font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={928} y={203} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"75% :"}
        </text>
      </switch>
      <path
        d="m915 192.5 6 6-6 6"
        fill="none"
        stroke="#1a1a1a"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 197,
              marginLeft: 985,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  {data?.info["pehsi-tera"]?.gtb ?? 0}
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={985} y={202} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"gt t6"}
        </text>
      </switch>
      <path
        d="m1111 221 .09 50.09h-70l-.09 14.58"
        fill="none"
        stroke="#000"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 169,
              marginLeft: 928,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">{"75% :"}</font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={928} y={174} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"75% :"}
        </text>
      </switch>
      <path
        d="m921 162.51-6 6 6 6"
        fill="none"
        stroke="#1a1a1a"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 53,
              height: 1,
              paddingTop: 169,
              marginLeft: 985,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  {data?.info["pehsi-tera"]?.ltb ?? 0}
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={985} y={174} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"lt t6"}
        </text>
      </switch>
      <circle
        cx={1041}
        cy={305.67}
        fill="#1a1a1a"
        stroke="#000"
        pointerEvents="none"
        r={20}
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe center",
              width: 8,
              height: 1,
              paddingTop: 308,
              marginLeft: 1037,
            }}
          >
            <div
              data-drawio-colors="color: rgb(0, 0, 0);"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 12,
                  fontFamily: "Helvetica",
                  color: "#000",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <b>
                  <font
                    style={{
                      fontSize: 15,
                    }}
                    color="#fff"
                  >
                    {"6"}
                  </font>
                </b>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={1041}
          y={311}
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
        >
          {"6"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 148,
              height: 1,
              paddingTop: 632,
              marginLeft: 943,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  {data?.info["okupansi-ip-backbone"]?.gtb ?? 0}
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={943} y={638} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"gt t7"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 146,
              height: 1,
              paddingTop: 605,
              marginLeft: 943,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  {data?.info["okupansi-ip-backbone"]?.ltb ?? 0}
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={943} y={611} fill="#FFF" fontFamily="Helvetica" fontSize={18}>
          {"lt t67"}
        </text>
      </switch>
      <path
        d="M740.9 336.04c0-3.34 10.54-6.04 23.55-6.04 13.01 0 23.55 2.7 23.55 6.04v31.69c0 3.33-10.54 6.04-23.55 6.04-13.01 0-23.55-2.71-23.55-6.04Z"
        fill="#65c97a"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <path
        d="M740.9 336.04c0 3.33 10.54 6.03 23.55 6.03 13.01 0 23.55-2.7 23.55-6.03M740.9 346c0 3.33 10.54 6.03 23.55 6.03 13.01 0 23.55-2.7 23.55-6.03m-47.1 11.32c0 3.33 10.54 6.03 23.55 6.03 13.01 0 23.55-2.7 23.55-6.03"
        fill="none"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="none"
      />
      <path
        d="M962.69 332.95v-30.03l16.14-3.92 13.53 4.03v30.37l-15.91 3.7Z"
        fill="#abb4c5"
        pointerEvents="none"
      />
      <path
        d="m962.69 302.92 16.14-3.92 13.53 4.03-15.91 3.81Z"
        fill="#b8bfd1"
        pointerEvents="none"
      />
      <path
        d="m976.45 306.84 15.91-3.81v30.37l-15.91 3.7Z"
        fill="#5b6369"
        pointerEvents="none"
      />
      <path
        d="M976.61 323.36c-2.97-1.13-5.8-2.55-8.46-4.26-1.28-.87-2.36-1.99-3.19-3.29a6.77 6.77 0 0 1-.84-3.32c-.05-1.25-.02-2.5.07-3.74.07-1.11.19-2.22.36-3.32.17-.61.42-1.19.76-1.72a9.7 9.7 0 0 1 2.31-1.12c1.45-.49 2.91-.9 4.39-1.24 1.55-.4 3.12-.7 4.71-.89 1.08-.14 2.17-.17 3.25-.12 1.77.13 3.51.51 5.16 1.13l5.39 1.94c.48.78.79 1.64.91 2.54.15 1.2.24 2.41.25 3.63.09 2.67.01 5.35-.22 8.02a5.64 5.64 0 0 1-1.16 3.25 6.895 6.895 0 0 1-4.03 2.13c-1.24.19-2.5.28-3.75.27Z"
        fillOpacity={0.4}
        fill="#fff"
        pointerEvents="none"
      />
      <path
        d="M964.28 307.52v-.79l10.35 3.14v.78Zm1.71 23.75v-.79l6.36 1.8v.78Z"
        fill="#52595e"
        pointerEvents="none"
      />
      <path
        d="M962.69 332.95v-30.03l16.14-3.92 13.53 4.03v30.37l-15.91 3.7Z"
        fill="none"
        stroke="#000"
        strokeWidth={0.27}
        strokeMiterlimit={10}
        strokeDasharray="0.8067300167691449 0.8067300167691449"
        pointerEvents="none"
      />
      <path
        d="M983.81 325.67c.55-1.83 3.59-3.17 7.18-3.17s6.63 1.34 7.18 3.17v10.24c-.55 1.83-3.59 3.18-7.18 3.18s-6.63-1.35-7.18-3.18Z"
        fill="#9ea5b5"
        stroke="#000"
        strokeWidth={0.2}
        strokeMiterlimit={10}
        strokeDasharray="0.5855515371716042 0.5855515371716042"
        pointerEvents="none"
      />
      <ellipse
        cx={990.97}
        cy={325.92}
        rx={7.175}
        ry={3.382}
        fill="#b7bfd1"
        pointerEvents="none"
      />
      <path
        d="m984.34 335.1.05-7.8c1.48 1.49 4.94 2.29 8.28 1.89 3.34-.39 5.65-1.86 5.53-3.52l-.03 7.97c-.08.87-.77 1.64-1.82 2.03-.87.27-1.98.35-2.98.19-1.01-.15-1.79-.51-2.09-.96-.45-.48-1.35-.83-2.41-.92-1.06-.1-2.14.07-2.91.44Z"
        fillOpacity={0.4}
        fill="#fff"
        pointerEvents="none"
      />
      <path
        d="M983.81 325.67c.55-1.83 3.59-3.17 7.18-3.17s6.63 1.34 7.18 3.17v10.24c-.55 1.83-3.59 3.18-7.18 3.18s-6.63-1.35-7.18-3.18Z"
        fill="none"
        stroke="#000"
        strokeWidth={0.2}
        strokeMiterlimit={10}
        strokeDasharray="0.5855515371716042 0.5855515371716042"
        pointerEvents="none"
      />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 27,
              height: 1,
              paddingTop: 344,
              marginLeft: 968,
            }}
          >
            <div
              data-drawio-colors="color: #6BA9CE;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#6ba9ce",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font size={1}>
                  <b
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {"NAT"}
                  </b>
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={968}
          y={349}
          fill="#6BA9CE"
          fontFamily="Helvetica"
          fontSize={16}
        >
          {"NAT"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 103,
              height: 1,
              paddingTop: 484,
              marginLeft: 763,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  <b>{"Node Service"}</b>
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={763} y={489} fill="#FFF" fontFamily="Helvetica" fontSize={16}>
          {"Node Service"}
        </text>
      </switch>
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          style={{
            overflow: "visible",
            textAlign: "left",
          }}
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: "flex",
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 103,
              height: 1,
              paddingTop: 521,
              marginLeft: 778,
            }}
          >
            <div
              data-drawio-colors="color: #FFFFFF;"
              style={{
                boxSizing: "border-box",
                fontSize: 0,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  fontFamily: "Helvetica",
                  color: "#fff",
                  lineHeight: 1.2,
                  pointerEvents: "none",
                  whiteSpace: "normal",
                  overflowWrap: "normal",
                }}
              >
                <font color="#1a1a1a">
                  <b>{"PCEF"}</b>
                </font>
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={778} y={526} fill="#FFF" fontFamily="Helvetica" fontSize={16}>
          {"PCEF"}
        </text>
      </switch>
      <circle
        cx={389}
        cy={420.38}
        fill="gray"
        stroke="#36393d"
        strokeWidth={0.1}
        strokeDasharray="0 0"
        pointerEvents="none"
        r={7.31}
      />
    </svg>
  );
};
export default SvgComponent;
