import { DatePicker, Form, Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
const { Option } = Select;

const unit = [
  "IPSN",
  "Backbone & DEFA Operation",
  "Wireless Operation",
  "IS Operation Support",
  "Infra Maintenance",
];
const status = ["Inprogress", "Done", "Cancel", "Pending"];

const nodeUnit = (
  <Select defaultValue={"IPSN"}>
    {unit.map((value) => (
      <Option value={value}>{value}</Option>
    ))}
  </Select>
);

const nodeStatus = (
  <Select defaultValue={"Inprogress"}>
    {status.map((value) => (
      <Option value={value}>{value}</Option>
    ))}
  </Select>
);

function formItemFactory(title) {
  if (title === "Status") {
    return nodeStatus;
  } else if (title === "Unit") {
    return nodeUnit;
  } else if (title === "Tanggal") {
    return <DatePicker />;
  } else if (title === "Program") {
    return <TextArea />;
  } else {
    return <Input />;
  }
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing || (record && record.new) ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {formItemFactory(title)}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
