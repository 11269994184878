import {
  Button,
  Form,
  Popconfirm,
  Space,
  message,
  Table,
  Tag,
  Typography,
  Spin,
  Row,
  // Input,
} from "antd";

import {
  LoadingOutlined,
  FileExcelFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import moment from "moment";
import EditableCell from "./EditableCell";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import * as XLSX from "xlsx";
import WeekPicker from "./WeekPicker";
import { useRef } from "react";
import { useContext } from "react";
import { AuthContext } from "../routes/authprovider";

const url = `${process.env.REACT_APP_NODE_API_URL}/program-kerja`;
async function queryFn(date) {
  const params = new URLSearchParams({
    year: `${date.year()}`,
    month: `${date.month() + 1}`,
    day: `${date.get("date")}`,
  });
  return await axios.get(url + "?" + params, {
    withCredentials: true,
  });
}

const customSpinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export function InlineEditTable() {
  const [form] = Form.useForm();
  const [buff, setBuff] = useState(null);
  const [data, setData] = useState([]);
  const [date, setDate] = useState(moment);
  const [editingKey, setEditingKey] = useState("");
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const tableElm = useRef();
  const { user } = useContext(AuthContext);

  const { isLoading, isFetching } = useQuery(
    ["program-kerja", date],
    () => queryFn(date),
    {
      onSuccess: (data) => {
        setData(data?.data);
      },
      onSettled: () => setBuff(null),
    }
  );
  const destroy = useMutation(
    (id) => {
      setLoading(true);
      return axios.delete(`${url}/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["program-kerja", date]);
        setLoading(false);
      },
      onError: (error) => {
        message.error(error.message);
        setLoading(false);
      },
    }
  );

  const post = useMutation(
    (newData) => {
      setLoading(true);
      return axios.post(`${url}`, {
        withCredentials: true,
        data: { ...newData, dateWeek: date },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["program-kerja", date]);
        setLoading(false);
      },
      onError: (error) => {
        message.error(error.message);
        setLoading(false);
      },
    }
  );

  const update = useMutation(
    ({ id, newData }) => {
      setLoading(true);
      return axios.put(`${url}/${id}`, {
        withCredentials: true,
        data: newData,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["program-kerja", date]);
        setLoading(false);
      },
      onError: (error) => {
        message.error(error.message);
      },
    }
  );

  const isEditing = (record) => record["_id"] === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      unit: "",
      program: "",
      status: "",
      pic: "",
      ...record,
      tanggal: moment(record.tanggal),
    });
    setEditingKey(record["_id"]);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => id === item["_id"]);
      if (index > -1) {
        update.mutate({ id: id, newData: row });
        queryClient.invalidateQueries(["program-kerja", date]);
        setEditingKey("");
      }
      // else {
      //   newData.push(row);
      //   setData(newData);
      //   setEditingKey("");
      // }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleAdd = (form) => {
    const buffers = data.filter((value) => value.new);
    const keys = buffers.map((value) => value.key.split("-").splice(-1));
    const newKey = buffers.length < 1 ? 0 : keys.slice(-1) + 1;
    const newData = {
      key: `buff-${newKey}`,
      unit: `IPSN`,
      program: ``,
      tanggal: moment(),
      pic: ``,
      status: `Inprogress`,
      new: true,
    };
    form.setFieldsValue({
      key: "",
      ...newData,
    });
    setBuff(newData.key);
    setData([...data, newData]);
  };

  const columns = [
    {
      align: "center",
      title: "Unit",
      dataIndex: "unit",
      width: "15%",
      editable: true,
    },
    {
      align: "center",
      title: "Program",
      dataIndex: "program",
      editable: true,
      width: "30%",
    },
    {
      align: "center",
      title: "Tanggal",
      dataIndex: "tanggal",
      editable: true,
      width: "15%",
      render: (text, record, _) => {
        return moment(text).format("D/MM/YYYY");
      },
    },
    {
      align: "center",
      title: "PIC",
      dataIndex: "pic",
      editable: true,
      width: "10%",
    },
    {
      align: "center",
      title: "Status",
      dataIndex: "status",
      editable: true,
      width: "5%",
      render: (text) => {
        let color = "";
        switch (text) {
          case "Inprogress":
            color = "processing";
            break;
          case "Done":
            color = "success";
            break;
          case "Pending":
            color = "warning";
            break;
          case "Cancel":
            color = "error";
            break;
          default:
            break;
        }
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      align: "center",
      title: "operation",
      dataIndex: "operation",
      width: "5%",
      render: (_, record) => {
        const editable = isEditing(record);
        if (record && record.new) {
          return (
            <Space size="middle">
              <Typography.Link
                onClick={async () => {
                  try {
                    const row = await form.validateFields();
                    post.mutate(row);
                    setBuff(null);
                  } catch (error) {}
                }}
                style={{
                  marginRight: 8,
                }}
              >
                Post
              </Typography.Link>
              <Typography.Link
                onClick={() => {
                  const newData = [...data];
                  newData.splice(
                    newData.findIndex((value) => {
                      return value.key === record.key;
                    }),
                    1
                  );
                  setBuff(null);
                  setData([...newData]);
                }}
              >
                Cancel
              </Typography.Link>
            </Space>
          );
        }
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record["_id"])}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Batalkan?" onConfirm={cancel}>
              <Typography.Link>Cancel</Typography.Link>
            </Popconfirm>
          </span>
        ) : user?.id === record.userId || user?.role === "admin" ? (
          <Space size={"middle"}>
            <Typography.Link
              disabled={editingKey !== "" || buff}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Hapus data?"
              onConfirm={() => {
                destroy.mutate(record["_id"]);
              }}
            >
              <Typography.Link disabled={editingKey !== "" || buff}>
                Delete
              </Typography.Link>
            </Popconfirm>
          </Space>
        ) : null;
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <>
        <WeekPicker date={date} setDate={setDate} />
        <Row justify="space-between">
          <Button
            type="primary"
            onClick={() => handleAdd(form)}
            disabled={buff !== null || editingKey !== ""}
            icon={<PlusOutlined />}
          >
            Buat baru
          </Button>
          <Button
            onClick={() => {
              let wb = XLSX.utils.book_new();
              let ws = XLSX.utils.json_to_sheet(
                data.map((value) => {
                  const { _id, createdAt, updatedAt, __v, ...rest } = value;
                  return rest;
                })
              );
              XLSX.utils.book_append_sheet(wb, ws);
              XLSX.writeFile(wb, `program-kerja.xlsx`);
            }}
            icon={
              <FileExcelFilled style={{ color: "green", fontSize: "16px" }} />
            }
            size="middle"
          >
            Download
          </Button>
        </Row>
      </>
      <Form form={form} component={false}>
        <Spin
          spinning={isLoading || isFetching || loading}
          indicator={customSpinIcon}
        >
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={data}
            columns={mergedColumns}
            rowClassName="editable-row"
            rowKey={(record) => record["_id"]}
            pagination={{
              onChange: cancel,
            }}
            ref={tableElm}
          />
        </Spin>
      </Form>
    </Space>
  );
}
