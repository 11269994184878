// import axios from "axios";
import { useEffect } from "react";
import { createContext, useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { useQuery } from "react-query";
const AuthContext = createContext();

// const queryFn = async () => {
//   return await axios.get("http://localhost:5000/poke", {
//     withCredentials: true,
//   });
// };

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // const { isLoading } = useQuery("poke", queryFn, {
  //   onSuccess: ({ data }) => {
  //     console.log("data(AuthProvider): ", data);
  //     setUser({ nama: data.nama, role: data.role, id: data.id });
  //   },
  //   onError: (error, variables) => {
  //     console.log(error);
  //     console.log(variables);
  //     setUser(null);
  //   },
  //   refetchOnWindowFocus: false,
  //   refetchOnMount: true,
  //   retry: false,
  // });
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  const value = { user, setUser };
  return isLoading ? null : (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
