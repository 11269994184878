import {
  Input,
  Form,
  Popconfirm,
  Space,
  Select,
  message,
  Table,
  Typography,
  Spin,
  Divider,
  Tag,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { useState } from "react";
import moment from "moment";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";

const url = `${process.env.REACT_APP_NODE_API_URL}/users`;
async function queryFn() {
  return await axios.get(url, {
    withCredentials: true,
  });
}

const customSpinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;

const unit = [
  "IPSN",
  "Backbone & DEFA Operation",
  "Wireless Operation",
  "IS Operation Support",
  "Infra Maintenance",
];

const nodeUnit = (
  <Select defaultValue={"IPSN"}>
    {unit.map((value) => (
      <Option value={value}>{value}</Option>
    ))}
  </Select>
);

const nodeStatus = (
  <Select defaultValue={"Inprogress"}>
    {["waiting", "verified"].map((value) => (
      <Option value={value}>{value}</Option>
    ))}
  </Select>
);

const nodeRole = (
  <Select defaultValue={"user"}>
    {["user", "admin"].map((value) => (
      <Option value={value}>{value}</Option>
    ))}
  </Select>
);

function formItemFactory(title) {
  if (title === "Status") {
    return nodeStatus;
  } else if (title === "Unit") {
    return nodeUnit;
  } else if (title === "Role") {
    return nodeRole;
  } else if (title === "Password") {
    return <Input.Password />;
  } else {
    return <Input />;
  }
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        title === "Password" ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                min: 8,
                message: "Password terlalu pendek (min: 8)",
              },
            ]}
          >
            {formItemFactory(title)}
          </Form.Item>
        ) : (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {formItemFactory(title)}
          </Form.Item>
        )
      ) : (
        children
      )}
    </td>
  );
};

export default function Users() {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);

  const [editingKey, setEditingKey] = useState("");
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const { isLoading, isFetching } = useQuery(["users"], () => queryFn(), {
    onSuccess: (data) => {
      setData(data?.data);
    },
    onError: (error) => {
      message.error(error?.response?.data || error?.message);
    },
  });
  const destroy = useMutation(
    (id) => {
      setLoading(true);
      return axios.delete(`${url}/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
        setLoading(false);
      },
      onError: (error) => {
        message.error(error.response?.data || error.message);
        setLoading(false);
      },
    }
  );

  const update = useMutation(
    ({ id, newData }) => {
      setLoading(true);
      return axios.put(`${url}/${id}`, {
        withCredentials: true,
        data: newData,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
        setLoading(false);
      },
      onError: (error) => {
        message.error(error?.response?.data || error?.message);
      },
    }
  );

  const isEditing = (record) => record["_id"] === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      unit: "",
      program: "",
      status: "",
      nik: "",
      role: "",
      ...record,
      newPass: "",
      tanggal: moment(record.tanggal),
    });
    setEditingKey(record["_id"]);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => id === item["_id"]);
      if (index > -1) {
        update.mutate({ id: id, newData: row });
        queryClient.invalidateQueries(["users"]);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      align: "center",
      title: "Nama",
      dataIndex: "nama",
      editable: true,
    },
    {
      align: "center",
      title: "NIK",
      dataIndex: "nik",
      width: "15%",
      editable: true,
    },
    {
      align: "center",
      title: "Unit",
      dataIndex: "unit",
      editable: true,
    },
    {
      align: "center",
      title: "Password",
      dataIndex: "newPass",
      editable: true,
    },
    {
      align: "center",
      title: "Role",
      dataIndex: "role",
      editable: true,
      width: "10%",
      render: (text) => {
        return <Tag color={text === "user" ? null : "#f50"}>{text}</Tag>;
      },
    },
    {
      align: "center",
      title: "Status",
      dataIndex: "status",
      editable: true,
      width: "10%",
      render: (text) => {
        return (
          <Tag color={text === "waiting" ? "warning" : "success"}>{text}</Tag>
        );
      },
    },
    {
      align: "center",
      title: "operation",
      dataIndex: "operation",
      width: "20%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record["_id"])}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Typography.Link>Cancel</Typography.Link>
            </Popconfirm>
          </span>
        ) : (
          <Space size={"middle"}>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title={`hapus ${record["nama"]}?`}
              onConfirm={() => {
                destroy.mutate(record["_id"]);
              }}
            >
              <Typography.Link disabled={editingKey !== ""}>
                Delete
              </Typography.Link>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <>
      <Typography.Title level={2}>Users</Typography.Title>
      <Divider />
      <Form form={form} component={false}>
        <Spin
          spinning={isLoading || isFetching || loading}
          indicator={customSpinIcon}
        >
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={data}
            columns={mergedColumns}
            rowClassName="editable-row"
            rowKey={(record) => record["_id"]}
            pagination={{
              onChange: cancel,
            }}
          />
        </Spin>
      </Form>
    </>
  );
}
